import constant from "../utils/GlobalValues";


export default class ConnectionUserToDanceGroupService {
    
    async update(
        jwt, 
        user_id,
        dance_group_id,
        value
    ){
        return await fetch(constant.baseDomain + "/api/connection_user_to_dance_group/update",
        {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "user_id" : user_id,
                "dance_group_id" : dance_group_id,
                "value" : value
            })
        }).then(res => res.json());
    }
}