


export default function DashboardPanel(props){

    const danceGroupMicroAnalyticsViewModelsProfits = props.danceGroupMicroAnalyticsViewModels.sort((a, b) => a.profit < b.profit ? 1 : -1);
    let danceGroupMicroAnalyticsProfiteRows = danceGroupMicroAnalyticsViewModelsProfits.map((danceGroupMicroAnalyticsViewModel, index) => {
        return <tr key={index}>
            <td>{index+1}</td>
            <td>{danceGroupMicroAnalyticsViewModel.name} <span>id{danceGroupMicroAnalyticsViewModel.id}</span></td>
            <td>{danceGroupMicroAnalyticsViewModel.profit}</td>
        </tr>
    })

    const danceGroupMicroAnalyticsViewModelsAttractedNewUsers = props.danceGroupMicroAnalyticsViewModels.sort((a, b) => a.attracted_new_users < b.attracted_new_users ? 1 : -1);
    let danceGroupMicroAnalyticsAttractedNewUsersRows = danceGroupMicroAnalyticsViewModelsAttractedNewUsers.map((danceGroupMicroAnalyticsViewModel, index) => {
        return <tr key={index}>
            <td>{index+1}</td>
            <td>{danceGroupMicroAnalyticsViewModel.name} <span>id{danceGroupMicroAnalyticsViewModel.id}</span></td>
            <td>{danceGroupMicroAnalyticsViewModel.attracted_new_users}</td>
        </tr>
    })

    let danceGroupMicroAnalyticsViewModelsRetentionRateViewModels = props.danceGroupMicroAnalyticsViewModels.filter(danceGroupMicroAnalyticsViewModel => danceGroupMicroAnalyticsViewModel.analyticsRetentionRateOfUsersByDanceGroupWithDatesFilter !== null);
    danceGroupMicroAnalyticsViewModelsRetentionRateViewModels = danceGroupMicroAnalyticsViewModelsRetentionRateViewModels.sort((a, b) => a.analyticsRetentionRateOfUsersByDanceGroupWithDatesFilter.retention_geometric_mean < b.analyticsRetentionRateOfUsersByDanceGroupWithDatesFilter.retention_geometric_mean ? 1 : -1);

    let danceGroupMicroAnalyticsViewModelsRetentionRateRows = danceGroupMicroAnalyticsViewModelsRetentionRateViewModels.map((danceGroupMicroAnalyticsViewModel, index) => {

        //const retentionRateLastLessonDate = new Date(Date.parse(danceGroupMicroAnalyticsViewModel.retentionRateOfUsersByTeacherByMonthLastViewModel.lesson_date_last));
        return <tr key={index}>
            <td>{index + 1}</td>
            <td>{danceGroupMicroAnalyticsViewModel.name} <span>id{danceGroupMicroAnalyticsViewModel.id}</span></td>
            <td>{danceGroupMicroAnalyticsViewModel.analyticsRetentionRateOfUsersByDanceGroupWithDatesFilter.error !== null && danceGroupMicroAnalyticsViewModel.analyticsRetentionRateOfUsersByDanceGroupWithDatesFilter.error !== "" 
            ? danceGroupMicroAnalyticsViewModel.analyticsRetentionRateOfUsersByDanceGroupWithDatesFilter.error
            : danceGroupMicroAnalyticsViewModel.analyticsRetentionRateOfUsersByDanceGroupWithDatesFilter.retention_geometric_mean + "%"}</td>
        </tr>
    })


    return (
        <>
            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
                <div className="analytics-block panel-success-alt">
                    <div className="icon"><i className="fa fa-ruble"></i></div>
                    <div>
                        <p className="desc">Доход</p>
                        <p className="value"><i className="fa fa-ruble"></i>{props.purchase_abonement_cash_amount + props.purchase_abonement_cashless_amount}</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
                <div className="analytics-block panel-info-alt">
                    <div className="icon"><i className="fa fa-users"></i></div>
                    <div>
                        <p className="desc">Новых клиентов</p>
                        <p className="value">{props.users_new_count}</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
                <div className="analytics-block panel-dark-alt">
                    <div className="icon"><i className="fa fa-pencil"></i></div>
                    <div>
                        <p className="desc">Визитов</p>
                        <p className="value">{props.visits_count}</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
                <div className="analytics-block panel-warning-alt">
                    <div className="icon"><i className="fa fa-money"></i></div>
                    <div>
                        <p className="desc">Прибыль</p>
                        <p className="value"><i className="fa fa-ruble"></i>{props.profit}</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
                <div className="analytics-block panel-dark-alt sm">
                    <div>
                        <p className="desc">Доход наличными<font className="right">безналичный</font></p>
                        <p className="value"><i className="fa fa-ruble"></i>{props.purchase_abonement_cash_amount}<font className="right"><i className="fa fa-ruble"></i>{props.purchase_abonement_cashless_amount}</font></p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
                <div className="analytics-block panel-dark-alt sm">
                    <div>
                        <p className="desc">Продано абонементов на сумму<font className="right">позиций</font></p>
                        <p className="value"><i className="fa fa-ruble"></i>{props.purchase_abonement_usual_amount}<font className="right">{props.purchase_abonement_usual_count}</font></p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
                <div className="analytics-block panel-dark-alt sm">
                    <div>
                        <p className="desc">Продано разовых на сумму<font className="right">позиций</font></p>
                        <p className="value"><i className="fa fa-ruble"></i>{props.purchase_abonement_raz_amount}<font className="right">{props.purchase_abonement_raz_count}</font></p>
                    </div>
                </div>
            </div>


            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
                <div className="analytics-block panel-dark-alt sm">
                    <div>
                        <p className="desc">Расходы на зарплаты<font className="right">позиций</font></p>
                        <p className="value"><i className="fa fa-ruble"></i>{props.teacher_salary_amount}<font className="right">{props.teacher_salary_count}</font></p>
                    </div>
                </div>
            </div>

            
            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                <div className="analytics-block analytics-table">
                    <p>Рейтинг групп по прибыли студии<br /><span>с учетом вычета зарплаты за период</span></p>
                    <table className="table table-striped table-bordered table-hover">
                        <tbody>
                            {danceGroupMicroAnalyticsProfiteRows}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                <div className="analytics-block analytics-table">
                    <p>Рейтинг групп по привлечению новых клиентов<br /><span>пришли в первый раз в студию к преподавателю</span></p>
                    <table className="table table-striped table-bordered table-hover">
                        <tbody>
                            {danceGroupMicroAnalyticsAttractedNewUsersRows}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                <div className="analytics-block analytics-table">
                    <p>Рейтинг преподавателей по удержанию учеников<br /><span>отображается геометрическое значение</span></p>
                    <table className="table table-striped table-bordered table-hover">
                        <tbody>
                            {danceGroupMicroAnalyticsViewModelsRetentionRateRows}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}