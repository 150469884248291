import { SystemErrorPage } from "../../pages/SystemError/SystemErrorPage";
import { SystemLoadingPage } from "../../pages/SystemLoading/SystemLoadingPage";
import imgUserDefault from "../../assets/images/user.png";
import { Button, Form } from "react-bootstrap";
import DateConverter from "../../utils/DateConverter";


export default function AdminEditModalBody(props){


    if(props.admin_id === 0)return <></>;
    if(props.isLoading)return <SystemLoadingPage />;
    if(props.isError)return <SystemErrorPage tryAgain={props.tryAgain} />;

    const dateConverter = new DateConverter();

    return (
        <div className="col-12 row admin">
            <div className="col-3 preview">
                <div className="text-center">
                    <img src={imgUserDefault} className="img-fluid poster" alt="admin-poster" />
                </div>
                <p className="title">Даты</p>
                <p>
                    Последняя активность:<br />
                    Дата регистрации: {props.date_of_add !== null ? dateConverter.toDateLikedmYis(props.date_of_add) : ""}
                </p>
                <Button variant="danger" type="button" size="sm"
                onClick={() => props.modalAdminDeletePrepareCallback(props.admin_id)}
                >Удалить</Button>
            </div>

            <div className="col-9">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="home" aria-selected="true"
                        >Профиль</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="panel-accesses-tab" data-bs-toggle="tab" data-bs-target="#panel-accesses" type="button" role="tab" aria-controls="panel-accesses" aria-selected="false"
                        >Доступы к вкладкам</button>
                    </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active profile" id="profile" role="tabpanel" aria-labelledby="profile-tab">


                        <Form>

                            <Form.Group controlId="formProfileActive">
                                <Form.Label>Доступ</Form.Label>
                                <Form.Control as="select" name="active" defaultValue={props.active}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formProfileSecondname">
                                <Form.Label>Логин</Form.Label>
                                <Form.Control type="text" name="username" placeholder="Логин" maxLength="216" defaultValue={props.username}
                                onChange={props.formAdminEditListener}
                                />
                            </Form.Group>

                            <Form.Group controlId="formProfileFirstname">
                                <Form.Label>Имя</Form.Label>
                                <Form.Control type="text" name="firstname" placeholder="Имя" maxLength="216" defaultValue={props.firstname}
                                onChange={props.formAdminEditListener}
                                />
                            </Form.Group>

                            <Form.Group controlId="formNewPassword">
                                <Form.Label>Новый пароль</Form.Label>
                                <Form.Control type="password" name="password_new" placeholder="Новый пароль" onChange={props.formAdminEditListener} />
                            </Form.Group>

                        </Form>

                    </div>
                    <div className="tab-pane fade" id="panel-accesses" role="tabpanel" aria-labelledby="panel-accesses-tab">
                        
                        <Form>

                            <Form.Group controlId="formPanelAdmins">
                                <Form.Label>Администраторы</Form.Label>
                                <Form.Control as="select" name="panel_admins" defaultValue={props.panel_admins}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanelLessons">
                                <Form.Label>Занятия</Form.Label>
                                <Form.Control as="select" name="panel_lessons" defaultValue={props.panel_lessons}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanelUsers">
                                <Form.Label>База клиентов</Form.Label>
                                <Form.Control as="select" name="panel_users" defaultValue={props.panel_users}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanelDanceGroups">
                                <Form.Label>Общее расписание</Form.Label>
                                <Form.Control as="select" name="panel_dance_groups" defaultValue={props.panel_dance_groups}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanelTeacherSalaries">
                                <Form.Label>Преподаватели</Form.Label>
                                <Form.Control as="select" name="panel_teachers" defaultValue={props.panel_teachers}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanel">
                                <Form.Label>Зарплаты преподавателей</Form.Label>
                                <Form.Control as="select" name="panel_teachers_salaries" defaultValue={props.panel_teachers_salaries}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanelAbonements">
                                <Form.Label>Абонементы</Form.Label>
                                <Form.Control as="select" name="panel_abonements" defaultValue={props.panel_abonements}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanelBranches">
                                <Form.Label>Филиалы</Form.Label>
                                <Form.Control as="select" name="panel_branches" defaultValue={props.panel_branches}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanelCashbox">
                                <Form.Label>Касса</Form.Label>
                                <Form.Control as="select" name="panel_cashbox" defaultValue={props.panel_cashbox}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formPanelSurveillance">
                                <Form.Label>Слежка</Form.Label>
                                <Form.Control as="select" name="panel_surveillance" defaultValue={props.panel_surveillance}
                                onChange={props.formAdminEditListener}
                                >
                                    <option value={0}>Закрыт</option>
                                    <option value={1}>Открыт</option>
                                </Form.Control>
                            </Form.Group>


                        </Form>
                    </div>
                    
                </div>
            </div>

        </div>
    )
}