import { Component } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import UserContext from "../../store/UserContext";
import AdminMiddleWare from "../../utils/AdminMiddleware";
import { Button } from "react-bootstrap";

class MainLayoutClass extends Component {

    static contextType = UserContext;

    constructor(props){
        super(props);
        this.actionLogout = this.actionLogout.bind(this);
        /*
        if(typeof(props.params) !== "undefined"){
            console.log("MainLayoutClass props.params.id: " + props.params.id);
        } else {
            console.log("не сработало");
        }
        */
    }

    actionLogout(){
        const adminMiddleWare = new AdminMiddleWare();
        adminMiddleWare.clearJWTCookie();
        //console.log(adminMiddleWare.getJWTFromCookie());
        const { logout } = this.context;
        logout();
        this.props.navigate("/login");
    }

    changeTitle(value){
        console.log("MainLayout changeTitle: " + value);
    }

    componentDidUpdate(){
        //console.log("MainLayout componentDidUpdate: ");
        
    }

    async getTeacherSalarySearchPrepare(){
        console.log("getTeacherSalarySearchPrepare");
    }


    render(){

        let title = "Рабочий стол";
        if((window.location.pathname).indexOf("/profile") + 1){title = "Профиль";}
        if((window.location.pathname).indexOf("/users") + 1){title = "База клиентов";}
        if((window.location.pathname).indexOf("/lessons") + 1){title = "Занятия";}
        if((window.location.pathname).indexOf("/teacher_salaries") + 1){title = "Зарплаты";}
        if((window.location.pathname).indexOf("/abonements") + 1){title = "Абонементы";}
        if((window.location.pathname).indexOf("/teachers") + 1){title = "Преподаватели";}
        if((window.location.pathname).indexOf("/branches") + 1){title = "Филиалы";}
        if((window.location.pathname).indexOf("/dance_groups") + 1){title = "Общее расписание";}
        if((window.location.pathname).indexOf("/admins") + 1){title = "Администраторы";}
        if((window.location.pathname).indexOf("/invoice") + 1){title = "Касса";}
        if((window.location.pathname).indexOf("/surveillances") + 1){title = "Отслеживание операций";}
        if((window.location.pathname).indexOf("/analytics/purchase_abonements") + 1){title = "История покупок";}
        document.title = title + " | 13 Dance Studio";
        //document.title = title + " | 8 Count 8";

        
        let liPanelAdmins = "";
        if(parseInt(localStorage.getItem("panel_admins"), 10) === 1){
            liPanelAdmins = <li><NavLink exact="true" activeclassname="active" to="/admins"><i className="fa fa-users"></i> Администраторы</NavLink></li>;
        }
        let liPanelLessons = "";
        if(parseInt(localStorage.getItem("panel_lessons"), 10) === 1){
            liPanelLessons = <li><NavLink exact="true" activeclassname="active" to="/lessons"><i className="fa fa-list"></i> Занятия</NavLink></li>
        }
        let liPanelUsers = "";
        if(parseInt(localStorage.getItem("panel_users"), 10) === 1){
            liPanelUsers = <li><NavLink exact="true" activeclassname="active" to="/users"><i className="fa fa-users"></i> База клиентов</NavLink></li>
        }
        let liPanelDanceGroups = "";
        if(parseInt(localStorage.getItem("panel_dance_groups"), 10) === 1){
            liPanelDanceGroups = <li><NavLink exact="true" activeclassname="active" to="/dance_groups"><i className="fa fa-calendar"></i> Общее расписание</NavLink></li>
        }
        let liPanelTeachers = "";
        if(parseInt(localStorage.getItem("panel_teachers"), 10) === 1){
            liPanelTeachers = <li><NavLink exact="true" activeclassname="active" to="/teachers"><i className="fa fa-users"></i> Преподаватели</NavLink></li>
        }
        let liPanelTeacherSalaries = "";
        if(parseInt(localStorage.getItem("panel_teacher_salaries"), 10) === 1){
            liPanelTeacherSalaries = <li><NavLink exact="true" activeclassname="active" to="/teacher_salaries"><i className="fa fa-ruble"></i> Зарплаты</NavLink></li>
        }
        let liPanelAbonements = "";
        if(parseInt(localStorage.getItem("panel_abonements"), 10) === 1){
            liPanelAbonements = <li><NavLink exact="true" activeclassname="active" to="/abonements"><i className="fa fa-shopping-cart"></i> Абонементы</NavLink></li>
        }
        let liPanelBranches = "";
        if(parseInt(localStorage.getItem("panel_branches"), 10) === 1){
            liPanelBranches = <li><NavLink exact="true" activeclassname="active" to="/branches"><i className="fa fa-home"></i> Филиалы</NavLink></li>
        }
        let liPanelCashbox = "";
        if(parseInt(localStorage.getItem("panel_cashbox"), 10) === 1){
            liPanelCashbox = <li><NavLink exact="true" activeclassname="active" to="/cashbox"><i className="fa fa-briefcase"></i> Касса</NavLink></li>
        }
        let liPanelSurveillances = "";
        if(parseInt(localStorage.getItem("panel_surveillance"), 10) === 1){
            liPanelSurveillances =  <li><NavLink exact="true" activeclassname="active" to="/surveillances"><i className="fa fa-eye"></i> Слежка</NavLink></li>
        }

        return (
            <>
                <div className="header">
                    <div className="left-panel">
                        <div className="short-info">
                            <p className="firstname">{localStorage.getItem("firstname")}</p>
                            <p className="position">Должность</p>
                        </div>
                    </div>
                    <div className="header-data">
                        <div className="row">
                            <div className="col-8">
                                <h3>{title}</h3>
                            </div>
                            <div className="col-4">
                                <div className="logout">
                                    <Button variant="danger" className="btn btn-sm" onClick={this.actionLogout}>Выйти</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu">
                    <div className="title">
                        <p>Навигация</p>
                    </div>
                    <ul>
                        <li><NavLink exact="true" activeclassname="active" to="/"><i className="fa fa-home"></i> Рабочий стол</NavLink></li>
                        <li><NavLink exact="true" activeclassname="active" to="/profile"><i className="fa fa-user"></i> Профиль</NavLink></li>
                        {liPanelLessons}
                        {liPanelUsers}
                        {liPanelTeacherSalaries}
                        {liPanelAbonements}
                        {liPanelTeachers}
                        {liPanelDanceGroups}
                        {liPanelBranches}
                        {liPanelAdmins}
                        {liPanelCashbox}
                        {liPanelSurveillances}

                        <li><NavLink exact="true" activeclassname="active" to="/analytics/purchase_abonements"><i className="fa fa-eye"></i> История покупок</NavLink></li>
                        
                    </ul>
                </div>

                <div className="main">
                    <Outlet changeTitle={this.changeTitle} />
                </div>

            </>
        )
    }
}

export default function MainLayout(props){
    const navigate = useNavigate();
    return(<MainLayoutClass {...props} navigate={navigate} params={useParams()}></MainLayoutClass>)
};