import constant from "../utils/GlobalValues";


export default class AdminService {


    async delete(jwt, admin_id){
        return await fetch(constant.baseDomain + "/api/admin/delete",
        {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "admin_id" : admin_id
            })
        }).then(res => res.json());
    }

    async getForEdit(jwt, admin_id){
        return await fetch(constant.baseDomain + "/api/admin/get",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "admin_id" : admin_id
            })
        }).then(res => res.json());
    }

    async search(jwt, page, query_string, is_need_count){
        return await fetch(constant.baseDomain + "/api/admin/search",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "page" : page,
                "query_string" : query_string,
                "is_need_count" : is_need_count
            })
        }).then(res => res.json());
    }

    async update(
        jwt, 
        admin_id,
        username, 
        firstname,
        password_new,
        active,
        panel_admins,
        panel_lessons,
        panel_users,
        panel_dance_groups,
        panel_teachers,
        panel_teacher_salaries,
        panel_abonements,
        panel_discounts,
        panel_cansels,
        panel_branches,
        panel_cashbox,
        panel_surveillance,
    ){
        return await fetch(constant.baseDomain + "/api/admin/update",
        {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "admin_id" : admin_id,
                "username" : username,
                "firstname" : firstname,
                "password_new" : password_new,
                "active" : active,
                "panel_admins" : panel_admins,
                "panel_lessons" : panel_lessons,
                "panel_users" : panel_users,
                "panel_dance_groups" : panel_dance_groups,
                "panel_teachers" : panel_teachers,
                "panel_teacher_salaries" : panel_teacher_salaries,
                "panel_abonements" : panel_abonements,
                "panel_discounts" : panel_discounts,
                "panel_cansels" : panel_cansels,
                "panel_branches" : panel_branches,
                "panel_cashbox" : panel_cashbox,
                "panel_surveillance" : panel_surveillance,
            })
        }).then(res => res.json());
    }

    async add(jwt, username, firstname){
        return await fetch(constant.baseDomain + "/api/admin/add",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "username" : username,
                "firstname" : firstname
            })
        }).then(res => res.json());
    }

}