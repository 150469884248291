import { Component } from "react";
import AdminService from "../../service/AdminService";
import AdminMiddleWare from "../../utils/AdminMiddleware";
import { Form, Button, Modal } from "react-bootstrap";


export default class AdminAddModal extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            adminService : new AdminService(),
            isLoading : false,
            modalAddUserIsShowing : false,
            dance_group_id : 0,
            newAdminUsername : "",
            newAdminFirstname : "",
            warning : "",
        }

        this.prepare = this.prepare.bind(this);
        this.modalAddnewAdminOpen = this.modalAddnewAdminOpen.bind(this);
        this.modalAddnewAdminClose = this.modalAddnewAdminClose.bind(this);
        this.newAdminFormListener = this.newAdminFormListener.bind(this);
        this.clearWarning = this.clearWarning.bind(this);
        this.addAdmin = this.addAdmin.bind(this);
    }

    modalAddnewAdminOpen(){
        this.setState({
            modalAddUserIsShowing : true
        });
    }

    modalAddnewAdminClose(){
        this.setState({
            modalAddUserIsShowing : false
        });
    }

    prepare(){
        this.setState({
            newAdminUsername : "",
            newAdminFirstname : "",
            warning : "",
        }, function(){
            this.modalAddnewAdminOpen();
        });
    }

    async addAdmin(){
        if(this.state.newAdminUsername === ""){
            this.setState({
                warning : "Поля 'Логин' обязательны для заполнения",
            });
            return;
        }
        this.setState({isLoading : true});
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        try {
            const jsonAnswerStatus = await this.state.adminService.add(
                jwt, 
                this.state.newAdminUsername,
                this.state.newAdminFirstname,
            );
            
            if(jsonAnswerStatus.status === "success"){
                this.modalAddnewAdminClose();
                this.props.addAdminSuccessCallback();
            } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "username_already_exist"){
                this.setState({
                    warning : "Логин уже зарегистрирован в базе",
                });
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            alert("Неизвестная ошибка на сервере");
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    newAdminFormListener(e){
        this.clearWarning();
        switch(e.target.name){
            case "username":
                this.setState({newAdminUsername : e.target.value});
                break;
            case "firstname":
                this.setState({newAdminFirstname : e.target.value});
                break;
            default:
                break;
        }
    }
    clearWarning(){
        this.setState({
            warning : "",
        });
    }



    render(){
        return(
            <>
                
                <Modal
                    show={this.state.modalAddUserIsShowing}
                    onHide={this.modalAddnewAdminClose}
                    animation={false}
                    size="lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>Новый администратор</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Логин</Form.Label>
                                <Form.Control name="username" type="text" placeholder="Логин" maxLength="216" 
                                onChange={this.newAdminFormListener} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Имя</Form.Label>
                                <Form.Control name="firstname" type="text" placeholder="Имя" maxLength="216" 
                                onChange={this.newAdminFormListener} />
                            </Form.Group>
                            <p className="warning">{this.state.warning}</p>
                        </Form>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.addAdmin} disabled={this.state.isLoading}>
                            Добавить
                        </Button>
                        <Button variant="secondary" onClick={this.modalAddnewAdminClose}>
                            Закрыть окно
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}