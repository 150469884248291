import { Component } from "react";
import { useNavigate } from "react-router-dom";
import DateConverter from "../utils/DateConverter";
import AdminMiddleWare from "../utils/AdminMiddleware";
import { Button, Form } from "react-bootstrap";
import { SystemLoadingPage } from "./SystemLoading/SystemLoadingPage";
import { SystemErrorPage } from "./SystemError/SystemErrorPage";
import PurchaseAbonementService from "../service/PurchaseAbonementService";
import HistoryPurchaseAbonementsTable from "../components/PurchaseAbonement/HistoryPurchaseAbonementsTable";



class HistoryPurchaseAbonements extends Component {

    constructor(props){
        super(props);
        const dateConverter = new DateConverter();
        this.state = {
            isLaunched : false,
            isLoading : false,
            isError: false,
            isSearching : false,

            purchaseAbonementService : new PurchaseAbonementService(),
            date_from : dateConverter.toStringLikeYmd(new Date()),
            date_to :  dateConverter.toStringLikeYmd(new Date()),
            purchaseAbonementLiteViewModels : [],
            //modalDeleteTeacherSalaryIsShowing : false,
        }
        this.filterListener = this.filterListener.bind(this);
        this.search = this.search.bind(this);
    }


    async search(){
        
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();
        
        try {
            this.setState({
                isLaunched : true,
                isLoading: true,
                isError: false
            });
            const jsonAnswerStatus = await this.state.purchaseAbonementService.listAllLiteByDateFilter(
                jwt,
                this.state.date_from,
                this.state.date_to
            );
            //console.log(jsonAnswerStatus);

            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.purchaseAbonementLiteViewModels !== null){
                this.setState({
                    purchaseAbonementLiteViewModels : jsonAnswerStatus.purchaseAbonementLiteViewModels
                });
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            this.setState({isError : true});
        } finally {
            this.setState({isLoading : false});
        }
        
    }

    filterListener(e){
        switch(e.target.name){
            case "date_from":
                this.setState({date_from : e.target.value});
                break;
            case "date_to":
                this.setState({date_to : e.target.value});
                break;
            default:
                break;
        }
    }


    render(){

        let purchaseAbonementsTable = "";

        if(this.state.isLoading){
            purchaseAbonementsTable = <SystemLoadingPage />
        } else if(this.state.isError){
            purchaseAbonementsTable = <SystemErrorPage tryAgain={this.search} />
        } else if(this.state.isLaunched){
            purchaseAbonementsTable = <HistoryPurchaseAbonementsTable
                isLoading={this.state.isLoading}
                isError={this.state.isError}
                purchaseAbonementLiteViewModels={this.state.purchaseAbonementLiteViewModels}
            />
        }

        return(
            <div className="row page analytics">
                <div className="col-12 row filter-block">
                    <div className="col-12">
                        <h5>Выберите даты</h5>
                    </div>
                    <div className="col-12 col-lg-8 col-md-12 col-sm-12 div-form row">
                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilterDateFrom">
                                    <Form.Label>С даты:</Form.Label>
                                    <Form.Control type="date" name="date_from" defaultValue={this.state.date_from} 
                                    onChange={this.filterListener}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilterDateTo">
                                    <Form.Label>По дату:</Form.Label>
                                    <Form.Control type="date" name="date_to" defaultValue={this.state.date_to} 
                                    onChange={this.filterListener}
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="col-12 text-right">
                            <Button variant="success" type="button" 
                            onClick={this.search} disabled={this.state.isLoading}
                            >
                                Поиск
                            </Button>
                        </div>

                    </div>

                    <div className="col-12">
                        {purchaseAbonementsTable}
                    </div>
                    
                </div>



            </div>
        )
    }

}




export default function HistoryPurchaseAbonementsPage(props){
    const navigate = useNavigate();
    return(<HistoryPurchaseAbonements navigate={navigate}></HistoryPurchaseAbonements>)
}