

export default class DateConverter {

    toStringLikeYmd(dateOfAction){
        if(dateOfAction === null || dateOfAction === "")return "";
        dateOfAction = new Date(dateOfAction);
        return dateOfAction.getFullYear() + "-" + ("0" + parseInt(dateOfAction.getMonth() + 1, 10)).slice(-2) + "-" + ("0" + dateOfAction.getDate()).slice(-2);
    }

    toDateLikedmYis(dateOfActionStr){
        if(dateOfActionStr === null || dateOfActionStr === "")return "";
        const dateOfAction = new Date(dateOfActionStr);
        return ("0" + dateOfAction.getDate()).slice(-2) + "." + ("0" + parseInt(dateOfAction.getMonth() + 1, 10)).slice(-2) + "." + dateOfAction.getFullYear() + " " + ("0" + parseInt(dateOfAction.getHours() + 1, 10)).slice(-2) + ":" + ("0" + parseInt(dateOfAction.getMinutes() + 1, 10)).slice(-2) + ":" + ("0" + parseInt(dateOfAction.getSeconds() + 1, 10)).slice(-2);
    }

    toDateLikedmY(dateOfActionStr){
        if(dateOfActionStr === null || dateOfActionStr === "")return "";
        const dateOfAction = new Date(dateOfActionStr);
        return ("0" + dateOfAction.getDate()).slice(-2) + "." + ("0" + parseInt(dateOfAction.getMonth() + 1, 10)).slice(-2) + "." + dateOfAction.getFullYear();
    }
    toDateLikedm(dateOfActionStr){
        if(dateOfActionStr === null || dateOfActionStr === "")return "";
        const dateOfAction = new Date(dateOfActionStr);
        return ("0" + dateOfAction.getDate()).slice(-2) + "." + ("0" + parseInt(dateOfAction.getMonth() + 1, 10)).slice(-2);
    }
}