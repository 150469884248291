import React, { Component } from "react"
import { useNavigate } from "react-router-dom";

import imgLogo from "../assets/images/logo.png"
import AdminMiddleWare from "../utils/AdminMiddleware";
import UserContext from "../store/UserContext";
import constant from "../utils/GlobalValues";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class LoginPage extends Component {

    static contextType = UserContext;

    constructor(props){
        super(props);
        this.state = {
            warning : "",
            username : "",
            password : "",
            isLoading : false
        }

        this.formLoginListener = this.formLoginListener.bind(this);
        this.formEnterListener = this.formEnterListener.bind(this);
        this.loginRequest = this.loginRequest.bind(this);
        this.clearWarning = this.clearWarning.bind(this);
        this.refLoginInput = React.createRef();
    }

    componentDidMount(){
        const {user} = this.context;
        console.log("context.isAuthed: " + user.isAuthed);
        this.refLoginInput.current.focus();
    }

    formEnterListener(e){
        if(e.key === "Enter"){
            this.loginRequest();
        }
    }
    formLoginListener(e){
        this.clearWarning();
        switch(e.target.name){
            case "username":
                this.setState({username : e.target.value.trim()});
                break;
            case "password":
                this.setState({password : e.target.value.trim()});
                break;
            default:
                break;
        }
    }
    
    loginRequest(){
        this.clearWarning();
        if(this.state.username === "" || this.state.password === ""){
            this.setState({warning : "Все поля обязательны для заполнения"});
            return;
        }
        
        this.setState({
            isLoading : true
        });
        try {
            const data = {
                username: this.state.username,
                password : this.state.password
            }
            fetch(constant.baseDomain + "/api/admin/login", 
                {
                    method : "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            )
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                console.log(jsonAnswerStatus);
                if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.access_token != null){
                    const adminMiddleWare = new AdminMiddleWare();
                    adminMiddleWare.setJWTToCookie(jsonAnswerStatus.access_token);
                    const { login } = this.context;
                    login();

                    if(jsonAnswerStatus.adminAccessViewModel !== null){
                        adminMiddleWare.updateAccessToMenuTabs(jsonAnswerStatus.adminAccessViewModel);
                    }

                    this.props.navigate("/");
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "wrong"){
                    this.setState({warning : "Неверный логин или пароль"});
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "accessDenied"){
                    this.setState({warning : "Извините, запрещен доступ в систему"});
                } else {
                    this.setState({warning : "Неизвестная ошибка на сервере"});
                }
                },
                (error) => {
                    this.setState({warning : "Ошибка на стороне сервера"});
                }
            )
        } catch(e) {
            this.setState({
                warning : "Неизвестная ошибка: " + e
            });
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    clearWarning(){
        this.setState({warning : ""});
    }


    render(){
        return (
            <div className="container">
                <div className="row">
                    <div className="col-4 d-none d-md-block"></div>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                        <div className="page login">
                            <center>
                                <img src={imgLogo} alt="logo" />
                            </center>
                            <Form>
                                <Form.Group className="mb-3" controlId="formLoginUsername">
                                    <Form.Label>Логин</Form.Label>
                                    <Form.Control type="email" placeholder="Логин" name="username" 
                                    ref={this.refLoginInput} 
                                    onChange={this.formLoginListener}
                                    onKeyPress={this.formEnterListener} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Пароль</Form.Label>
                                    <Form.Control type="password" placeholder="Пароль" name="password" 
                                    onChange={this.formLoginListener}
                                    onKeyPress={this.formEnterListener} />
                                </Form.Group>
                                <div className="text-center">
                                    <Button variant="success" type="button" onClick={this.loginRequest} disabled={this.state.isLoading}>
                                        Войти
                                    </Button>
                                </div>
                                <p className="warning">{this.state.warning}</p>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export function LoginPageWithRouter(props){
    const navigate = useNavigate();
    return(<LoginPage navigate={navigate}></LoginPage>)
}

export default LoginPage;