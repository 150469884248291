import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import AdminService from "../service/AdminService";
import { SystemLoadingPage } from "./SystemLoading/SystemLoadingPage";
import { SystemErrorPage } from "./SystemError/SystemErrorPage";
import AdminMiddleWare from "../utils/AdminMiddleware";
import AdminSearchPreviewRow from "../components/Admin/AdminSearchPreviewRow";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import AdminAddModal from "../components/Admin/AdminAddModal";
import AdminEditModal from "../components/Admin/AdminEditModal";
import AdminDeleteModal from "../components/Admin/AdmindDeleteModal";



class Admins extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLaunched : false,
            isPreparing : false,
            isLoading : false,
            isError: false,

            adminService : new AdminService(),
            isLaunch : false,
            activePage : 1,
            queryString : null,
            isNeedCount : true,
            countUserSearchQueryAll : 0,
            countUserSearchQuery : 0,
            countPages : 0,
            adminSearchPreviewViewModels : [],
        }
        this.refModalAddAdmin = React.createRef();
        this.search = this.search.bind(this);
        this.searchFromTop = this.searchFromTop.bind(this);
        this.searchPage = this.searchPage.bind(this);
        this.searchPageNext = this.searchPageNext.bind(this);
        this.searchPagePrev = this.searchPagePrev.bind(this);
        this.modalAddAdminPrepare = this.modalAddAdminPrepare.bind(this);
        this.addAdminSuccess = this.addAdminSuccess.bind(this);

        this.refModalEditAdmin = React.createRef();
        this.getAdminPrepare = this.getAdminPrepare.bind(this);
        this.adminUpdateSuccess = this.adminUpdateSuccess.bind(this);

        this.refModalDeleteAdmin = React.createRef();
        this.adminDeletePrepare = this.adminDeletePrepare.bind(this);
        this.adminDeleteSuccess = this.adminDeleteSuccess.bind(this);
        //this.XXXXXXXXXXXXXX = this.XXXXXXXXXXXXXX.bind(this);
        //this.XXXXXXXXXXXXXX = this.XXXXXXXXXXXXXX.bind(this);
    }

    async componentDidMount(){
        await this.search();
    }

    async search(){
        this.setState({isLoading : true});
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        try {
            const jsonAnswerStatus = await this.state.adminService.search(
                jwt, 
                this.state.activePage, 
                this.state.queryString, 
                this.state.isNeedCount
            );
            
            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.adminSearchPreviewViewModels !== null){
                //this.props.addUserCallback();
                if(!this.state.isLaunch){
                    this.setState({
                        isLaunch : true,
                        countUserSearchQueryAll : jsonAnswerStatus.countUserSearchQuery
                    });
                }
                if(this.state.isNeedCount){
                    let countPages = 0;
                    if(jsonAnswerStatus.countUserSearchQuery !== 0){
                        countPages = Math.ceil(jsonAnswerStatus.countUserSearchQuery / 20)
                    }
                    this.setState({
                        countUserSearchQuery : jsonAnswerStatus.countUserSearchQuery,
                        countPages : countPages,
                        isNeedCount : false
                    });
                }
                this.setState({
                    adminSearchPreviewViewModels : jsonAnswerStatus.adminSearchPreviewViewModels
                });
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            this.setState({isError : true});
        } finally {
            this.setState({isLoading : false});
        }
    }

    searchFromTop(){
        this.setState({
            activePage : 1,
            isNeedCount : true
        }, async function(){
            //console.log("this.state.isNeedCount: " + this.state.isNeedCount);
            await this.search();
        });
    }
    searchPage(value){
        //console.log("searchPage");
        this.setState({
            activePage : value
        }, () => {
            this.search();
        });
    }
    searchPageNext(){
        if(this.state.activePage >= this.state.countPages)return;
        this.setState({
            activePage : this.state.activePage + 1
        }, () => {
            this.search();
        });
    }
    searchPagePrev(){
        if(this.state.activePage <= 1)return;
        this.setState({
            activePage : this.state.activePage - 1
        }, () => {
            this.search();
        });
    }

    modalAddAdminPrepare(){
        this.refModalAddAdmin.current.prepare();
    }
    addAdminSuccess(){
        this.setState({
            isNeedCount : true,
            adminSearchPreviewViewModels : [],
        }, function(){
            this.searchFromTop();
        });
    }

    getAdminPrepare(adminId){
        this.refModalEditAdmin.current.getForEdit(adminId);
    }
    adminUpdateSuccess(){
        this.search();
    }

    adminDeletePrepare(adminId){
        this.refModalDeleteAdmin.current.prepare(adminId);
    }
    adminDeleteSuccess(){
        this.setState({
            isNeedCount : true,
            adminSearchPreviewViewModels : [],
        }, function(){
            this.search();
        });
    }

    render(){

        let adminSearchPreviews = <></>;
        if(this.state.isLoading){
            adminSearchPreviews = <SystemLoadingPage />
        } else if(this.state.isError){
            adminSearchPreviews = <SystemErrorPage tryAgain={this.search} />
        } else if(this.state.adminSearchPreviewViewModels.length > 0){
            adminSearchPreviews = this.state.adminSearchPreviewViewModels.map((content, index) => {
                return <AdminSearchPreviewRow
                key={index}
                id={content.id}
                username={content.username}
                firstname={content.firstname}
                active={content.active}
                getAdminCallback={this.getAdminPrepare}
                />
            });
        } else {
            adminSearchPreviews = <center><i>- записей не найдено -</i></center>
        }

        let isPaginationLeftEnabled = false;
        let pagePaginationMin = this.state.activePage;
        let isPaginationRightEnabled = false;
        let pagePaginationMax = this.state.activePage;
        if(this.state.activePage - 1 > 0){
            pagePaginationMin = this.state.activePage - 1;
        }
        if(this.state.activePage - 2 > 0){
            isPaginationLeftEnabled = true;
            pagePaginationMin = this.state.activePage - 2;
        }
        if(this.state.activePage + 1 < this.state.countPages){
            pagePaginationMax = this.state.activePage + 1;
        }
        if(this.state.activePage + 2 < this.state.countPages){
            isPaginationRightEnabled = true;
            pagePaginationMax = this.state.activePage + 2;
        }

        let paginationPages = [];
        for(var i = pagePaginationMin; i <= pagePaginationMax; i++){
            paginationPages.push(i);
            //paginationBtns += <li className={i === this.state.activePage ? "active" : ""}>{i}</li>
        }
        const paginationBtns = paginationPages.map((page) => {
            return <li key={page} className={page === this.state.activePage ? "active" : ""} onClick={() => this.searchPage(page)}>{page}</li>
        })

        //console.log("this.state.isWorkingWithUser: " + this.state.isWorkingWithUser);

        return(

            <div className="row page users">
                <div className={this.state.isWorkingWithUser ? "hide" : "col-3"}>
                    <div className="filter-global">
                        <p>Фильтрация</p>
                        <ul>
                            <li className="active" onClick={this.searchFromTop}>Все <span>{this.state.countUserSearchQueryAll}</span></li>
                        </ul>

                        <Button type="button" variant="success" 
                        onClick={this.modalAddAdminPrepare} 
                        disabled={this.state.isLoading}
                        >
                            <i className="fa fa-plus"></i> Добавить
                        </Button>

                        <AdminAddModal
                        ref={this.refModalAddAdmin}
                        addAdminSuccessCallback={this.addAdminSuccess}
                        />

                    </div>
                </div>
                <div className={this.state.isWorkingWithUser ? "hide" : "col-9 right-block-preview-results"}>
                    <div className="col-12 row filter">
                        <div className="col-10">
                            <InputGroup>
                                <Form.Control type="text" placeholder="Поиск клиентов" maxLength="256"
                                onKeyDown={this.handleKeyDown}
                                onChange={this.filterFormListener} />
                            </InputGroup>
                        </div>
                        <div className="col-2">
                            <Button variant="success" 
                            onClick={this.searchFromTop} disabled={this.state.isLoading}>
                                Поиск
                            </Button>

                        </div>
                        <div className={this.state.isLoading ? "hide" : "col-12 results-pagination"}>
                            <p className="find">Найденное:</p>
                            <p className="find-count">{this.state.countUserSearchQuery} пользователей ({this.state.countPages} страниц)</p>
                            <ul>
                                <li className={isPaginationLeftEnabled ? "" : "disabled"}
                                onClick={this.searchPagePrev}><i className="fa fa-angle-left"></i></li>
                                {paginationBtns}
                                <li className={isPaginationRightEnabled ? "" : "disabled"}
                                onClick={this.searchPageNext}><i className="fa fa-angle-right"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 row">

                        {adminSearchPreviews}

                    </div>

                    <Modal
                    show={this.state.modalDeleteUserIsShowing}
                    onHide={this.modalDeleteUserClose}
                    animation={false}
                    size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Удалить пользователя</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Вы уверены, что хотите удалить пользователя?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.deleteUserFetch}>
                                Да
                            </Button>
                            <Button variant="secondary" onClick={this.modalDeleteUserClose}>
                                Закрыть окно
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <div>
                    <div //User
                    //userModel = {this.state.userModel}
                    //returnToSearchPage = {this.returnToSearchPage}
                    //dayOfWeek = {this.state.dayOfWeek}
                    //schedule = {this.state.schedule}
                    //refreshUser = {this.refreshUser}
                    //userPurchaseList = {this.state.userPurchaseList}
                    //visitList = {this.state.visitList}
                    //discountList = {this.state.discountList}
                    //abonementPrivateList = {this.state.abonementPrivateList}
                    //userAdminDanceGroupList = {this.state.userAdminDanceGroupList}
                    //afterDeleteUser = {this.afterDeleteUser}
                    />
                </div>

                <AdminEditModal
                ref={this.refModalEditAdmin}
                adminUpdateSuccessCallback={this.adminUpdateSuccess}
                modalAdminDeletePrepareCallback={this.adminDeletePrepare}
                />

                <AdminDeleteModal
                ref={this.refModalDeleteAdmin}
                adminDeleteSuccessCallback={this.adminDeleteSuccess}
                />
            </div>
        )
    }
}


export default function AdminsPage(props){
    const navigate = useNavigate();
    return(<Admins navigate={navigate}></Admins>)
}