import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import DateConverter from "../utils/DateConverter";
import { useNavigate } from "react-router-dom";
import AdminMiddleWare from "../utils/AdminMiddleware";
import VisitService from "../service/VisitService";
import ConnectionUserToDanceGroupService from "../service/ConnectionUserToDanceGroupService";
import DanceGroupUserVisitLessonTable from "../components/Lesson/DanceGroupUserVisitLessonTable";
import VisitNewModal from "../components/Visit/VisitNewModal";
import VisitDeleteModal from "../components/Visit/VisitDeleteModal";
import PurchaseAbonementNewModal from "../components/PurchaseAbonement/PurchaseAbonementNewModal";
import PurchaseAbonementDeleteModal from "../components/PurchaseAbonement/PurchaseAbonementDeleteModal";
import TeacherSalaryInfoModal from "../components/TeacherSalary/TeacherSalaryInfoModal";
import PurchaseAbonementsOnDateOfActionModal from "../components/PurchaseAbonement/PurchaseAbonementsOnDateOfActionModal";
import UserAddModal from "../components/User/UserAddModal";
import ConnectionUserToDanceGroupNewModal from "../components/ConnectionUserToDanceGroup/ConnectionUserToDanceGroupNewModal";



class Lessons extends Component {

    constructor(props){
        super(props);
        let dateConverter = new DateConverter();
        const dateNow = new Date();
        this.state = {
            isLaunched : false,
            isPreparing : false,
            isLoading : false,
            isError: false,
            visitService : new VisitService(),
            connectionUserToDanceGroupService : new ConnectionUserToDanceGroupService(),
            
            date_from : dateConverter.toStringLikeYmd(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)),
            date_to :  dateConverter.toStringLikeYmd(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)),
            dance_group_id : 0,
            filter_time : 30,

            danceGroupMicroViewModels : [],

            dateVisitLessonViewModels : [],
            userVisitLessonViewModels : [],
            sumOfPriceForPurchasesByDateOfActionForLessonViewModels : [],
            sumOfUsersForLessonViewModels : [],
            teacherSalaryForLessonViewModels : [],
        }
        this.getLessonSearchPrepare = this.getLessonSearchPrepare.bind(this);
        this.filterListener = this.filterListener.bind(this);
        this.search = this.search.bind(this);

        this.refModalAddUser = React.createRef();
        this.modalAddUserPrepare = this.modalAddUserPrepare.bind(this);

        this.refVisitNewModal = React.createRef();
        this.visitNewModalInit = this.visitNewModalInit.bind(this);
        this.visitNewSuccess = this.visitNewSuccess.bind(this);
        this.visitDeletePrepare = this.visitDeletePrepare.bind(this);
        this.visitDeleteSuccess = this.visitDeleteSuccess.bind(this);
        this.refVisitDeleteModal = React.createRef();

        this.refPurchaseAbonementNewModal = React.createRef();
        this.purchaseAbonementNewPrepare = this.purchaseAbonementNewPrepare.bind(this);
        this.modalPurchaseAbonementNewSuccess = this.modalPurchaseAbonementNewSuccess.bind(this);

        this.refPurchaseAbonementDeleteModal = React.createRef();
        this.purchaseAbonementDeletePrepare = this.purchaseAbonementDeletePrepare.bind(this);
        this.modalPurchaseAbonementDeleteSuccess = this.modalPurchaseAbonementDeleteSuccess.bind(this);

        this.refTeacherSalaryInfoModalBody = React.createRef();
        this.teacherSalaryGetMoreInfo = this.teacherSalaryGetMoreInfo.bind(this);

        this.refPurchaseAbonementsOnDateOfActionModal = React.createRef();
        this.purchaseAbonementsOnDateOfActionModalInit = this.purchaseAbonementsOnDateOfActionModalInit.bind(this);

        this.refConnectionUserToDanceGroupNewModal = React.createRef();
        this.connectionUserToDanceGroupNewModalInit = this.connectionUserToDanceGroupNewModalInit.bind(this);
        this.connectionUserToDanceGroupNewSuccess = this.connectionUserToDanceGroupNewSuccess.bind(this);

        this.connectionUserToDanceGroupDelete = this.connectionUserToDanceGroupDelete.bind(this);
    }

    async componentDidMount(){
        await this.getLessonSearchPrepare();
    }
    
    async filterListener(e)
    {
        console.log("filterListener e.target.name: " + e.target.name);
        switch(e.target.name)
        {
            case "date_from":
                this.setState({date_from : e.target.value});
                break;
            case "date_to":
                this.setState({date_to : e.target.value});
                break;
            case "dance_group_id":
                this.setState({
                    dance_group_id : parseInt(e.target.value, 10)
                }, function()
                {
                    this.search()
                });
                break;
            case "filter_time":
                this.setState({filter_time : parseInt(e.target.value, 10)});
                break;
            default:
                break;
        }
    }

    async search(){
        if(this.state.dance_group_id === 0)return;
        for(var i = 0; i < this.state.danceGroupMicroViewModels.length; i++){
            if(this.state.dance_group_id === this.state.danceGroupMicroViewModels[i].id){
                document.title = this.state.danceGroupMicroViewModels[i].name;
                break;
            }
        }
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        this.setState({
            isLaunched : true,
            isPreparing : true,
            isLoading: true,
            isError: false
        });
        try {
            const jsonAnswerStatus = await this.state.visitService.getDanceGroupUserVisitLesson(
                jwt,
                this.state.dance_group_id,
                this.state.filter_time,
                this.state.date_from,
                this.state.date_to
            );
            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.danceGroupUserVisitLessonViewModel !== null){

                let userVisitLessonViewModels = jsonAnswerStatus.danceGroupUserVisitLessonViewModel.userVisitLessonViewModels;
                userVisitLessonViewModels = userVisitLessonViewModels.sort((a,b) => {
                    return a.userMicroViewModel.secondname.localeCompare(b.userMicroViewModel.secondname);
                });

                this.setState({
                    dateVisitLessonViewModels : jsonAnswerStatus.danceGroupUserVisitLessonViewModel.dateVisitLessonViewModels,
                    userVisitLessonViewModels : userVisitLessonViewModels,
                    sumOfPriceForPurchasesByDateOfActionForLessonViewModels : jsonAnswerStatus.danceGroupUserVisitLessonViewModel.sumOfPriceForPurchasesByDateOfActionForLessonViewModels,
                    sumOfUsersForLessonViewModels : jsonAnswerStatus.danceGroupUserVisitLessonViewModel.sumOfUsersForLessonViewModels,
                    teacherSalaryForLessonViewModels : jsonAnswerStatus.danceGroupUserVisitLessonViewModel.teacherSalaryForLessonViewModels,
                });
            } else {
                alert("Ошибка связи с сервером");
            }
        } catch(e){
            this.setState({
                isError : true,
            });
        } finally {
            this.setState({
                isPreparing : false,
                isLoading : false,
            });
        }
    }

    async getLessonSearchPrepare(){
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        const jsonAnswerStatus = await this.state.visitService.getLessonSearchPrepare(jwt);

        if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.lessonSearchPrepareViewModel !== null){

            const danceGroupMicroViewModels = jsonAnswerStatus.lessonSearchPrepareViewModel.danceGroupMicroViewModels.sort((a,b) => {
                return (a.name).localeCompare(b.name);
            });

            this.setState({
                danceGroupMicroViewModels : danceGroupMicroViewModels
            });
        } else {
            alert("Ошибка связи с сервером");
        }
    }

    modalAddUserPrepare(){
        this.refModalAddUser.current.prepare(this.state.dance_group_id);
    }

    visitNewModalInit(
        user_id,
        dance_group_id,
        dance_group_day_of_week_id,
        date_of_action
    ){
        this.refVisitNewModal.current.getVisitPrepare(
            user_id,
            dance_group_id,
            dance_group_day_of_week_id,
            date_of_action
        );
    }
    visitNewSuccess(){
        this.search();
    }
    visitDeletePrepare(
        user_id,
        visit_id, 
        purchase_abonement_id, 
        purchase_abonement_name
    ){
        console.log("LessonsPage visitDeletePrepare");
        this.refVisitDeleteModal.current.prepare(
            user_id,
            visit_id,
            purchase_abonement_id,
            purchase_abonement_name
        );
    }
    visitDeleteSuccess(){
        this.search();
    }

    purchaseAbonementNewPrepare(userId, abonementId, danceGroupId, danceGroupDayOfWeekId, date_of_buy){
        //console.log("LessonPage purchaseAbonementNewPrepare date_of_buy: " + date_of_buy);
        this.refPurchaseAbonementNewModal.current.getForBuy(userId, abonementId, danceGroupId, danceGroupDayOfWeekId, date_of_buy);
    }
    modalPurchaseAbonementNewSuccess(){
        this.search();
    }

    purchaseAbonementDeletePrepare(purchaseAbonementId, purchaseAbonementName){
        this.refPurchaseAbonementDeleteModal.current.prepare(purchaseAbonementId, purchaseAbonementName);
    }
    modalPurchaseAbonementDeleteSuccess(){
        this.search();
    }

    teacherSalaryGetMoreInfo(
        dance_group_id,
        dance_group_day_of_week_id,
        teacher_id,
        date_of_action
    ){
        console.log("LessonsPage teacher_id: " + teacher_id);
        this.refTeacherSalaryInfoModalBody.current.getMoreInfo(
            dance_group_id,
            dance_group_day_of_week_id,
            teacher_id,
            date_of_action
        )
    }

    purchaseAbonementsOnDateOfActionModalInit(dateOfAction){
        this.refPurchaseAbonementsOnDateOfActionModal.current.search(dateOfAction);
    }

    async connectionUserToDanceGroupDelete(userId){
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        this.setState({
            isLoading: true,
            isError: false
        });
        try {
            const jsonAnswerStatus = await this.state.connectionUserToDanceGroupService.update(
                jwt,
                userId,
                this.state.dance_group_id,
                false
            );
            if(jsonAnswerStatus.status === "success"){
                this.search();
            } else {
                alert("Ошибка связи с сервером");
            }
        } catch(e){
            alert("Ошибка связи с сервером");
        } finally {
            /*
            this.setState({
                isPreparing : false,
                isLoading : false,
                userVisitLessonViewModels : [],
            });
            */
        }
    }

    connectionUserToDanceGroupNewModalInit(){
        this.refConnectionUserToDanceGroupNewModal.current.prepare(
            this.state.dance_group_id,
            new Date()
        );
    }
    connectionUserToDanceGroupNewSuccess(){
        console.log("LessonsPage connectionUserToDanceGroupNewSuccess");
        this.search();
    }

    render(){

        let danceGroupMicroOptions = "";
        if(this.state.danceGroupMicroViewModels.length > 0){
            danceGroupMicroOptions = this.state.danceGroupMicroViewModels.map((danceGroupMicroViewModel) => {
                return <option key={danceGroupMicroViewModel.id} value={danceGroupMicroViewModel.id}>{danceGroupMicroViewModel.id} - {danceGroupMicroViewModel.name}</option>
            });
        }

        return (

            <div className="row page lessons">
                <div className="col-12 row filter-block">
                    <div className="col-12">
                        <h5>После выбора группы ниже отобразиться список подключенных к группе учеников</h5>
                    </div>
                    <div className="col-12 col-lg-8 col-md-12 col-sm-12 div-form row">

                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilteDanceGroup">
                                    <Form.Label>Фильтр по группе:</Form.Label>
                                    <Form.Control as="select" name="dance_group_id" defaultValue={this.state.dance_group_id}
                                    disabled={this.state.isLoading} 
                                    onChange={this.filterListener}
                                    >
                                        <option value={0}>- любая</option>
                                        {danceGroupMicroOptions}
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilteTeacher">
                                    <Form.Label>Фильтр по времени:</Form.Label>
                                    <Form.Control as="select" name="filter_time" defaultValue={this.state.filter_time} 
                                    disabled={this.state.isLoading} 
                                    onChange={this.filterListener}
                                    >
                                        <option value="30">Всех подключенных, кто приходил за последние 30 дней</option>
                                        <option value="90">Подключенных за последние 3 месяца</option>
                                        <option value="0">Всех подключенных когда-либо</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilterDateFrom">
                                    <Form.Label>С даты:</Form.Label>
                                    <Form.Control type="date" name="date_from" defaultValue={this.state.date_from} 
                                    disabled={this.state.isLoading} 
                                    onChange={this.filterListener}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilterDateTo">
                                    <Form.Label>По дату:</Form.Label>
                                    <Form.Control type="date" name="date_to" defaultValue={this.state.date_to}
                                    disabled={this.state.isLoading}  
                                    onChange={this.filterListener}
                                    />
                                </Form.Group>
                            </Form>
                        </div>


                        <div className="col-12 text-right">
                            <Button variant="success" type="button" 
                            onClick={this.search} disabled={this.state.isLoading || this.state.dance_group_id === 0}
                            >
                                Поиск
                            </Button>

                            <Button variant="info" type="button" 
                             disabled={this.state.isLoading || this.state.dance_group_id === 0}
                            onClick={this.connectionUserToDanceGroupNewModalInit}
                            >
                                Подключить ученика к группе
                            </Button>

                            <Button variant="secondary" type="button" 
                            disabled={this.state.isLoading || this.state.dance_group_id === 0}
                            onClick={this.modalAddUserPrepare}
                            >
                                Добавить клиента
                            </Button>
                        </div>

                    </div>
                    
                </div>

                <div className="col-12">
                    <DanceGroupUserVisitLessonTable
                    isLaunched={this.state.isLaunched}
                    isLoading={this.state.isLoading}
                    isError={this.state.isError}
                    tryAgain={this.search}
                    dateVisitLessonViewModels={this.state.dateVisitLessonViewModels}
                    userVisitLessonViewModels={this.state.userVisitLessonViewModels}
                    sumOfPriceForPurchasesByDateOfActionForLessonViewModels={this.state.sumOfPriceForPurchasesByDateOfActionForLessonViewModels}
                    sumOfUsersForLessonViewModels={this.state.sumOfUsersForLessonViewModels}
                    teacherSalaryForLessonViewModels={this.state.teacherSalaryForLessonViewModels}

                    visitNewModalInitCallback={this.visitNewModalInit}
                    teacherSalaryGetMoreInfoCallback={this.teacherSalaryGetMoreInfo}
                    purchaseAbonementsOnDateOfActionModalInitCallback={this.purchaseAbonementsOnDateOfActionModalInit}
                    connectionUserToDanceGroupDeleteCallback={this.connectionUserToDanceGroupDelete}
                    />
                </div>


                <UserAddModal
                ref={this.refModalAddUser}
                addUserCallback={this.search}
                />
                
                <VisitNewModal
                ref={this.refVisitNewModal}
                visitNewSuccessCallback={this.visitNewSuccess}
                visitDeletePrepareCallback={this.visitDeletePrepare}
                purchaseAbonementNewPrepareCallback={this.purchaseAbonementNewPrepare}
                purchaseAbonementDeletePrepareCallback={this.purchaseAbonementDeletePrepare}
                />

                <VisitDeleteModal
                ref={this.refVisitDeleteModal}
                visitDeleteSuccessCallback={this.visitDeleteSuccess}
                />

                <PurchaseAbonementNewModal
                ref={this.refPurchaseAbonementNewModal}
                modalPurchaseAbonementNewSuccessCallback={this.modalPurchaseAbonementNewSuccess}
                />

                <PurchaseAbonementDeleteModal
                ref={this.refPurchaseAbonementDeleteModal}
                modalPurchaseAbonementDeleteSuccessCallback={this.modalPurchaseAbonementDeleteSuccess}
                />
                
                <TeacherSalaryInfoModal
                ref={this.refTeacherSalaryInfoModalBody}
                />

                <PurchaseAbonementsOnDateOfActionModal
                ref={this.refPurchaseAbonementsOnDateOfActionModal}
                />

                <ConnectionUserToDanceGroupNewModal
                ref={this.refConnectionUserToDanceGroupNewModal}
                connectionUserToDanceGroupNewSuccessCallback={this.connectionUserToDanceGroupNewSuccess}
                />

            </div>
        )
    }
}


export default function LessonsPage(props){
    const navigate = useNavigate();
    return(<Lessons navigate={navigate}></Lessons>)
}