import { Table } from "react-bootstrap";
import { SystemErrorPage } from "../../pages/SystemError/SystemErrorPage"
import { SystemLoadingPage } from "../../pages/SystemLoading/SystemLoadingPage"
import DateConverter from "../../utils/DateConverter";

export default function HistoryPurchaseAbonementsTable(props){
    
    if(props.isLoading)return <SystemLoadingPage />
    if(props.isError)return <SystemErrorPage tryAgain={props.tryAgainCallback} />

    let purchaseAbonementName = "";
    let purchaseAbonementLites = "";
    let amountOfCashForPurchaseAbonements = 0;
    let amountOfCashlessForPurchaseAbonements = 0;
    let amountOfPriceForPurchaseAbonements = 0;
    if(props.purchaseAbonementLiteViewModels.length > 0){
        let schetchik = 0;
        const dateConverter = new DateConverter();
        const purchaseAbonementLiteRows = props.purchaseAbonementLiteViewModels.map((purchaseAbonementLiteViewModel, index) => {
            schetchik++;

            const userMicroViewModel = purchaseAbonementLiteViewModel.userMicroViewModel !== null ? purchaseAbonementLiteViewModel.userMicroViewModel : null;

            const abonementLiteViewModel = purchaseAbonementLiteViewModel.abonementLiteViewModel !== null ? purchaseAbonementLiteViewModel.abonementLiteViewModel : null;
            purchaseAbonementName = abonementLiteViewModel !== null ? abonementLiteViewModel.name : "<абонемент не найден>";
            const dateOfBuy = new Date(purchaseAbonementLiteViewModel.date_of_buy);

            let labelPurchaseAbonementInfo = "";
            if(purchaseAbonementLiteViewModel.date_of_activation === null){
                labelPurchaseAbonementInfo = "Изначально "+purchaseAbonementLiteViewModel.visits_start+" осталось " + purchaseAbonementLiteViewModel.visits_left + " занятий (куплен " + dateConverter.toDateLikedmY(dateOfBuy) + ", не акт) ";
            } else {

                const dateOfActivation = typeof(purchaseAbonementLiteViewModel.date_of_activation) !== "undefined" && purchaseAbonementLiteViewModel.date_of_activation !== null ? new Date( purchaseAbonementLiteViewModel.date_of_activation) : null;
                const dateOfActivationStr = dateOfActivation !== null ? dateConverter.toDateLikedmY(dateOfActivation) : "<ошибка даты>";

                const dateOfMustBeUsedTo = typeof(purchaseAbonementLiteViewModel.date_of_must_be_used_to) !== "undefined" && purchaseAbonementLiteViewModel.date_of_must_be_used_to !== null ? new Date( purchaseAbonementLiteViewModel.date_of_must_be_used_to) : null;
                const dateOfMustBeUsedToStr = dateOfMustBeUsedTo !== null ? dateConverter.toDateLikedmY(dateOfMustBeUsedTo) : "<ошибка даты>";

                labelPurchaseAbonementInfo = "Изначально "+purchaseAbonementLiteViewModel.visits_start+" осталось " + purchaseAbonementLiteViewModel.visits_left + " (куплен " + dateConverter.toDateLikedmY(dateOfBuy) + ", акт. " 
                + dateOfActivationStr + ", действителен до " + dateOfMustBeUsedToStr;
            }

            amountOfCashForPurchaseAbonements += purchaseAbonementLiteViewModel.price;
            amountOfCashlessForPurchaseAbonements +=  purchaseAbonementLiteViewModel.cashless;
            amountOfPriceForPurchaseAbonements += purchaseAbonementLiteViewModel.price + purchaseAbonementLiteViewModel.cashless;

            return <tr key={index}>
                <td>{schetchik}</td>
                <td>{purchaseAbonementLiteViewModel.id}</td>
                <td>{dateConverter.toDateLikedmY(purchaseAbonementLiteViewModel.date_of_buy)}</td>
                <td>{dateConverter.toDateLikedmYis(purchaseAbonementLiteViewModel.date_of_add)}</td>
                <td>{userMicroViewModel !== null ? userMicroViewModel.secondname + " " + userMicroViewModel.firstname : "<пользователь не найден>"}</td>
                <td>{purchaseAbonementName + " | " + labelPurchaseAbonementInfo}</td>
                <td>{purchaseAbonementLiteViewModel.price}</td>
                <td>{purchaseAbonementLiteViewModel.cashless}</td>
                <td>{purchaseAbonementLiteViewModel.price + purchaseAbonementLiteViewModel.cashless}</td>
            </tr>
        });

        purchaseAbonementLites = <Table striped bordered hover>
            <thead>
                <tr>
                    <th>
                        №
                    </th>
                    <th>
                        ID
                    </th>
                    <th>
                        Дата покупки
                    </th>
                    <th>
                        Дата добавления
                    </th>
                    <th>
                        ФИО
                    </th>
                    <th>
                        Купленный абонемент
                    </th>
                    <th>
                        Нал
                    </th>
                    <th>
                        Безнал
                    </th>
                    <th>
                        Сумма
                    </th>
                </tr>
            </thead>
            <tbody>
                {purchaseAbonementLiteRows}
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="td-amount-of-sum">Итого:</td>
                    <td className="td-amount-of-sum">{amountOfCashForPurchaseAbonements}</td>
                    <td className="td-amount-of-sum">{amountOfCashlessForPurchaseAbonements}</td>
                    <td className="td-amount-of-sum">{amountOfPriceForPurchaseAbonements}</td>
                </tr>
            </tbody>
        </Table>;
    } else {
        purchaseAbonementLites = <center><i>- записей не найдено -</i></center>
    }

    return purchaseAbonementLites;
}