import constant from "../utils/GlobalValues";


export default class VisitService 
{
    
    async getDanceGroupUserVisitLesson(
        jwt,
        dance_group_id,
        filter_time,
        date_from,
        date_to
    )
    {
        return await fetch(constant.baseDomain + "/api/visit/get_dance_group_user_visit_lesson",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body : JSON.stringify({
                "dance_group_id" : dance_group_id,
                "filter_time" : filter_time,
                "date_from" : date_from,
                "date_to" : date_to
            })
        }).then(res => res.json());
    }

    async listAllLiteOfUser(jwt, user_id, dance_group_id, date_of_action){
        return await fetch(constant.baseDomain + "/api/visit/list_all_lite_of_user",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "user_id" : user_id,
                "dance_group_id" : dance_group_id,
                "date_of_action" : date_of_action
            })
        }).then(res => res.json());
    }


    async add(jwt, user_id, dance_group_id, dance_group_day_of_week_id, purchase_abonement_id, date_of_action){
        return await fetch(constant.baseDomain + "/api/visit/add",
        {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "user_id" : user_id,
                "dance_group_id" : dance_group_id,
                "dance_group_day_of_week_id" : dance_group_day_of_week_id,
                "purchase_abonement_id" : purchase_abonement_id,
                "date_of_action" : date_of_action
            })
        }).then(res => res.json());
    }

    async prepare(jwt, user_id, dance_group_id, dance_group_day_of_week_id, date_of_action){
        return await fetch(constant.baseDomain + "/api/visit/prepare",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "user_id" : user_id,
                "dance_group_id" : dance_group_id,
                "dance_group_day_of_week_id" : dance_group_day_of_week_id,
                "date_of_action" : date_of_action
            })
        }).then(res => res.json());
    }
    
    async getLessonSearchPrepare(jwt)
    {
        return await fetch(constant.baseDomain + "/api/visit/get_lesson_search_prepare",
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        }).then(res => res.json());
    }

    async delete(jwt, visit_id){
        return await fetch(constant.baseDomain + "/api/visit/delete",
        {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "visit_id" : visit_id
            })
        }).then(res => res.json());
    }


}