import { Component } from "react";
import AdminService from "../../service/AdminService";
import AdminMiddleWare from "../../utils/AdminMiddleware";
import { Button, Modal } from "react-bootstrap";


export default class AdminDeleteModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading : false,
            isError : false,
            
            adminService : new AdminService(),
            modalAdminDeleteIsShowing : false,
            admin_id : 0,
        }
        this.modalAdminDeleteOpen = this.modalAdminDeleteOpen.bind(this);
        this.modalAdminDeleteClose = this.modalAdminDeleteClose.bind(this);
        this.prepare = this.prepare.bind(this);
        this.delete = this.delete.bind(this);
    }

    modalAdminDeleteOpen(){
        this.setState({
            modalAdminDeleteIsShowing : true
        });
    }

    modalAdminDeleteClose(){
        this.setState({
            modalAdminDeleteIsShowing : false
        });
    }

    prepare(admin_id){
        this.setState({
            admin_id : admin_id
        }, function(){
            this.modalAdminDeleteOpen();
        });
    }

    async delete(){
        this.setState({
            isLoading : true, 
            isError : false,
        });

        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();
        
        try {
            const jsonAnswerStatus = await this.state.adminService.delete(
                jwt, 
                this.state.admin_id
            );
            
            if(jsonAnswerStatus.status === "success" ){
                this.modalAdminDeleteClose();
                this.props.adminDeleteSuccessCallback();
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            alert("Неизвестная ошибка на сервере");
        } finally {
            this.setState({isLoading : false});
        }
    }

    render(){
        return (
            <Modal
                show={this.state.modalAdminDeleteIsShowing}
                onHide={this.modalAdminDeleteClose}
                animation={false}
                size="lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Удаление администратора
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Вы уверены, что хотите удалить администратора?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.delete} disabled={this.state.isLoading}>
                        Удалить
                    </Button>
                    <Button variant="secondary" onClick={this.modalAdminDeleteClose}>
                        Закрыть окно
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}