import React, { Component } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DateConverter from "../utils/DateConverter";
import AdminMiddleWare from "../utils/AdminMiddleware";
import CashboxService from "../service/CashboxService";
import { SystemLoadingPage } from "./SystemLoading/SystemLoadingPage";
import { SystemErrorPage } from "./SystemError/SystemErrorPage";



class Cashbox extends Component {

    constructor(props){
        super(props);
        const dateConverter = new DateConverter();
        this.state = {
            isLaunched : false,
            isLoading : false,
            isError: false,
            isSearching : false,

            cashboxService : new CashboxService(),
            date_from : dateConverter.toStringLikeYmd(new Date()),
            date_to :  dateConverter.toStringLikeYmd(new Date()),
            cashboxPurchaseAbonementViewModels : [],
            teacherSalaryLiteViewModels : [],
            //modalDeleteTeacherSalaryIsShowing : false,
        }
        this.filterListener = this.filterListener.bind(this);
        this.search = this.search.bind(this);
        //this.refTeacherSalaryInfoModalBody = React.createRef();
    }

    /*
    modalTeacherSalaryDeleteOpen(){
        this.setState({
            modalDeleteTeacherSalaryIsShowing : true
        });
    }

    modalTeacherSalaryDeleteClose(){
        this.setState({
            modalDeleteTeacherSalaryIsShowing : false
        });
    }
    */

    filterListener(e){
        switch(e.target.name){
            case "date_from":
                this.setState({date_from : e.target.value});
                break;
            case "date_to":
                this.setState({date_to : e.target.value});
                break;
            default:
                break;
        }
    }

    async search(){
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        try {
            this.setState({
                isLaunched : true,
                isLoading: true,
                isError: false
            });
            const jsonAnswerStatus = await this.state.cashboxService.search(
                jwt,
                this.state.date_from,
                this.state.date_to
            );
            //console.log(jsonAnswerStatus);

            this.setState({
                isLoading : false,
                isError : false
            });
            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.cashboxByDateFilterViewModel !== null){

                let cashboxPurchaseAbonementViewModels = jsonAnswerStatus.cashboxByDateFilterViewModel.cashboxPurchaseAbonementViewModels;
                cashboxPurchaseAbonementViewModels = cashboxPurchaseAbonementViewModels.sort((a, b) => function(){
                    return a.abonementLiteViewModel.name.localeCompare(b.abonementLiteViewModel.name);
                });

                this.setState({
                    cashboxPurchaseAbonementViewModels : cashboxPurchaseAbonementViewModels,
                    teacherSalaryLiteViewModels : jsonAnswerStatus.cashboxByDateFilterViewModel.teacherSalaryLiteViewModels,
                });
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            this.setState({isError : true});
        } finally {
            this.setState({isLoading : false});
        }
    }


    render(){

        let cashboxTable = "";
        let cashboxPurchaseAbonementRows = "";
        let cashboxTeacherSalaryRows = "";
        let cashboxAmountCashAndCashless = "";

        let totalAmountPurchaseAbonementPrice = 0;
        let totalAmountTeacherSalaryPrice = 0;
        let totalAmountCash = 0;
        let totalAmountCashless = 0;
        if(this.state.cashboxPurchaseAbonementViewModels.length > 0 ){
            cashboxPurchaseAbonementRows = this.state.cashboxPurchaseAbonementViewModels.map((cashboxPurchaseAbonementViewModel, index) => {

                const totalPrice = cashboxPurchaseAbonementViewModel.total_cash + cashboxPurchaseAbonementViewModel.total_cashless;
                totalAmountPurchaseAbonementPrice += totalPrice;

                totalAmountCash += cashboxPurchaseAbonementViewModel.total_cash;
                totalAmountCashless += cashboxPurchaseAbonementViewModel.total_cashless;

                return <tr key={index}>
                    <td>
                        {cashboxPurchaseAbonementViewModel.abonementLiteViewModel.name}
                    </td>
                    <td>
                        {cashboxPurchaseAbonementViewModel.abonementLiteViewModel.price}
                    </td>
                    <td>
                        {cashboxPurchaseAbonementViewModel.count}
                    </td>
                    <td>
                        {cashboxPurchaseAbonementViewModel.total_cash}
                    </td>
                    <td>
                        {cashboxPurchaseAbonementViewModel.total_cashless}
                    </td>
                    <td>
                        {totalPrice}
                    </td>
                </tr>
            });

            cashboxAmountCashAndCashless = <tr>
                <td className="td-amount-of-sum">Итого доход</td>
                <td></td>
                <td></td>
                <td className="td-amount-of-sum">{totalAmountCash}</td>
                <td className="td-amount-of-sum">{totalAmountCashless}</td>
                <td className="td-amount-of-sum">{totalAmountCash + totalAmountCashless}</td>
            </tr>
        }

        if(this.state.teacherSalaryLiteViewModels.length > 0 ){
            const dateConverter = new DateConverter();
            cashboxTeacherSalaryRows = this.state.teacherSalaryLiteViewModels.map((teacherSalaryLiteViewModel, index) => {

                totalAmountTeacherSalaryPrice += teacherSalaryLiteViewModel.price;

                const danceGroupLessonViewModel = teacherSalaryLiteViewModel.danceGroupLessonViewModel;

                return <tr key={index}>
                    <td>
                        Зарплата {dateConverter.toDateLikedmY(teacherSalaryLiteViewModel.date_of_action)} {teacherSalaryLiteViewModel.teacherMicroViewModel !== null ? teacherSalaryLiteViewModel.teacherMicroViewModel.name : "<Преподаватель не найден>"} за группу "{danceGroupLessonViewModel !== null ? danceGroupLessonViewModel.name : "<группа не найдена>"}" {danceGroupLessonViewModel !== null ? danceGroupLessonViewModel.time_from + "-" + danceGroupLessonViewModel.time_to : ""}
                    </td>
                    <td>
                        {teacherSalaryLiteViewModel.price}
                    </td>
                    <td>
                        1
                    </td>
                    <td>
                    </td>
                    <td>

                    </td>
                    <td>
                        {teacherSalaryLiteViewModel.status === 1 ? -teacherSalaryLiteViewModel.price : 0}
                    </td>
                </tr>
            });
        }

        if(this.state.isLoading){
            cashboxTable = <SystemLoadingPage />
        } else if(this.state.isError){
            cashboxTable = <SystemErrorPage tryAgain={this.search} />
        } else if(cashboxPurchaseAbonementRows !== "" || cashboxTeacherSalaryRows !== ""){
            cashboxTable = <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Наименование</th>
                    <th>Цена</th>
                    <th>Количество</th>
                    <th>Наличными</th>
                    <th>Безналичный расчёт</th>
                    <th>Сумма</th>
                </tr>
            </thead>
            <tbody>
                {cashboxPurchaseAbonementRows}
                {cashboxAmountCashAndCashless}
                {cashboxTeacherSalaryRows}
            </tbody>
        </Table>
        } else if(this.state.isLaunched) {
            cashboxTable = <center><i>- записей не найдено -</i></center>
        }

        return(
            <div className="row page cashbox">
                <div className="col-12 row filter-block">
                    <div className="col-12">
                        <h5>Для получения подробного анализа выберите даты</h5>
                    </div>
                    <div className="col-12 col-lg-8 col-md-12 col-sm-12 div-form row">
                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilterDateFrom">
                                    <Form.Label>С даты:</Form.Label>
                                    <Form.Control type="date" name="date_from" defaultValue={this.state.date_from} 
                                    onChange={this.filterListener}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilterDateTo">
                                    <Form.Label>По дату:</Form.Label>
                                    <Form.Control type="date" name="date_to" defaultValue={this.state.date_to} 
                                    onChange={this.filterListener}
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="col-12 text-right">
                            <Button variant="success" type="button" 
                            onClick={this.search} disabled={this.state.isLoading}
                            >
                                Поиск
                            </Button>
                        </div>

                    </div>
                    
                </div>

                <div className="col-12">
                    {cashboxTable}

                    <div className="result">
                        <p>Доход: <font>₽{totalAmountPurchaseAbonementPrice}</font></p>
                        <p>Расход: <font>₽{totalAmountTeacherSalaryPrice}</font></p>
                        <p>Прибыль: <font>₽{totalAmountPurchaseAbonementPrice - totalAmountTeacherSalaryPrice}</font></p>
                    </div>
                </div>


            </div>
        )
    }

}


export default function CashboxPage(props){
    const navigate = useNavigate();
    return(<Cashbox navigate={navigate}></Cashbox>)
}