import { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import constant from "../utils/GlobalValues";
import AdminMiddleWare from "../utils/AdminMiddleware";
import { SystemLoadingPage } from "./SystemLoading/SystemLoadingPage";


class Profile extends Component {

    constructor(props){
        super(props);
        this.state = {
            username : "",
            firstname : "",
            passwordCurrent : "",
            passwordNew : "",
            passwordNewAgain : "",
            warning : "",
            success : "",

            isLaunched : false,
            isError: false,
            isLoading : false,
            isFetching : false
        }
        this.launch = this.launch.bind(this);
        this.clearWarning = this.clearWarning.bind(this);
        this.profileFormChangeListener = this.profileFormChangeListener.bind(this);
        this.save = this.save.bind(this);
    }
    componentDidMount(){
        this.launch();
    }

    launch(){
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();
        fetch(constant.baseDomain + "/api/admin/profile/get",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoading : true
                });
                if(result["adminProfileViewModel"] !== null && typeof(result["adminProfileViewModel"]) !== "undefined"){
                    localStorage.setItem("firstname", result["adminProfileViewModel"]["firstname"]);
                    this.setState({
                        username : result["adminProfileViewModel"]["username"],
                        firstname : result["adminProfileViewModel"]["firstname"]
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoading : true,
                    isError : true
                });
            }
        )
    }

    profileFormChangeListener(event){
        this.clearWarning();
        switch(event.target.name){
            case "username":
                this.setState({
                    username : event.target.value
                });
                break;
            case "firstname":
                this.setState({
                    firstname : event.target.value
                });
                break;
            case "passwordCurrent":
                this.setState({
                    passwordCurrent : event.target.value
                });
                break;
            case "passwordNew":
                this.setState({
                    passwordNew : event.target.value
                });
                break;
            case "passwordNewAgain":
                this.setState({
                    passwordNewAgain : event.target.value
                });
                break;
            default:
                break;
        }
    }


    clearWarning(){
        this.setState({
            warning : "",
            success : ""
        });
    }

    save(){
        if(this.state.username === ""){
            this.setState({
                warning : "Поле 'логин' не может быть пустым"
            });
            return;
        }
        if(this.state.passwordNew !== "" && this.state.passwordNew !== this.state.passwordNewAgain){
            this.setState({
                warning : "Пароли не совпадают"
            });
            return;
        }
        if(this.state.passwordNew !== "" && this.state.passwordCurrent === ""){
            this.setState({
                warning : "Введите пожалуйста текущий пароль"
            });
            return;
        }

        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();
        if(this.state.newPassword !== "" && this.state.password === ""){
            this.setState({
                warning : "Не указан текущий пароль"
            });
            return;
        }

        this.setState({
            isFetching : true
        });

        fetch(constant.baseDomain + "/api/admin/profile/update",
        {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "username" : this.state.username,
                "firstname" : this.state.firstname,
                "password_new" : this.state.passwordNew,
                "password_current" : this.state.passwordCurrent
            })
        }
        )
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                  isFetching : false
                });
                if(result.status === "success"){
                    this.setState({
                        success : "Успешно сохранено"
                    });
                } else if(result.status === "error" && result.errors === "username_already_exist"){
                    this.setState({
                        warning : "данный логин уже есть в базе"
                    });
                } else if(result.status === "error" && result.errors === "current_password_wrong"){
                    this.setState({
                        warning : "Неправильно введён текущий пароль"
                    });
                } else {
                    this.setState({
                        warning : "Неизвестная ошибка на сервере"
                    });
                }

                if(result.access_token !== "undefined" && typeof result.access_token !== "undefined" && result.access_token !== "" && result.access_token !== null){
  
                    //this.props.loginToken(result.jwt);
  
                    //const { cookies } = this.props;
                    //cookies.set('adminCookie', result.jwt, { path: '/' });
  
                }
            },
            (error) => {
                this.setState({
                  warning : "Неизвестная ошибка на сервере",
                  isFetching : false
                });
            }
        )
    }

    render(){
        if(!this.state.isLoading){
            return (
                <SystemLoadingPage />
            )
        }

        return(
            <div className="row page profile">
                <div className="col-12">
                    <Form>
                        <Form.Group controlId="formProfileUsername">
                            <Form.Label>Логин</Form.Label>
                            <Form.Control type="email" name="username" placeholder="Логин" maxLength="216" defaultValue={this.state.username} 
                            onChange={this.profileFormChangeListener}
                            />
                            <Form.Text className="text-muted">
                                
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formProfilePosition">
                            <Form.Label>Имя</Form.Label>
                            <Form.Control type="text" name="firstname" placeholder="Имя" maxLength="216" defaultValue={this.state.firstname}
                            onChange={this.profileFormChangeListener}
                            />
                        </Form.Group>

                        <hr />

                        <Form.Group controlId="formNewPassword">
                            <Form.Label>Новый пароль</Form.Label>
                            <Form.Control type="password" name="passwordNew" placeholder="Новый пароль" onChange={this.profileFormChangeListener} />
                        </Form.Group>

                        <Form.Group controlId="formPasswordNewAgain">
                            <Form.Label>Новый пароль еще раз</Form.Label>
                            <Form.Control type="password" name="passwordNewAgain" placeholder="Новый пароль еще раз" onChange={this.profileFormChangeListener} />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Текущий пароль</Form.Label>
                            <Form.Control type="password" name="passwordCurrent" placeholder="Текущий пароль" onChange={this.profileFormChangeListener} />
                        </Form.Group>

                        <Button variant="success" type="button" onClick={this.save} disabled={this.state.isFetching}>
                            Сохранить
                        </Button>

                        <p className="warning">{this.state.warning}</p>
                        <p className="success">{this.state.success}</p>
                        
                    </Form>
                </div>
            </div>
        )
    }

}


export default function ProfilePage(props){
    const navigate = useNavigate();
    return(<Profile navigate={navigate}></Profile>)
}