import constant from "../utils/GlobalValues";


export default class UserService {


    async update(
        jwt, 
        user_id,
        username,
        password,
        secondname,
        firstname,
        patronymic,
        telephone,
        gender,
        birthday,
        parent_fio,
        parent_phone,
        comment
    ){
        return await fetch(constant.baseDomain + "/api/admin/user/profile/update",
        {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "user_id" : user_id,
                "username" : username,
                "password" : password,
                "secondname" : secondname,
                "firstname" : firstname,
                "patronymic" : patronymic,
                "telephone" : telephone,
                "gender" : gender,
                "birthday" : birthday,
                "parent_fio" : parent_fio,
                "parent_phone" : parent_phone,
                "comment" : comment
            })
        }).then(res => res.json());
    }

    async delete(jwt, user_id){
        return await fetch(constant.baseDomain + "/api/admin/user/delete",
        {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "user_id" : user_id
            })
        }).then(res => res.json());
    }

    async getProfile(jwt, user_id){
        return await fetch(constant.baseDomain + "/api/admin/user/profile/get",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "user_id" : user_id
            })
        }).then(res => res.json());
    }

    async getSearchPreview(jwt, user_id){
        return await fetch(constant.baseDomain + "/api/admin/user/get_search_preview",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "user_id" : user_id
            })
        }).then(res => res.json());
    }

    async add(jwt, secondname, firstname, telephone, comment, dance_group_id){
        return await fetch(constant.baseDomain + "/api/admin/user/add",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "secondname" : secondname,
                "firstname" : firstname,
                "telephone" : telephone,
                "comment" : comment,
                "dance_group_id" : dance_group_id
            })
        }).then(res => res.json());
    }

    async search(jwt, page, query_string, is_need_count){
        return await fetch(constant.baseDomain + "/api/admin/user/search",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "page" : page,
                "query_string" : query_string,
                "is_need_count" : is_need_count
            })
        }).then(res => res.json());
    }

    async searchMicro(jwt, query_string){
        return await fetch(constant.baseDomain + "/api/admin/user/search_micro",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "query_string" : query_string
            })
        }).then(res => res.json());
    }
    
}