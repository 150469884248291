import { Component } from "react";
import PurchaseAbonementService from "../../service/PurchaseAbonementService";
import AdminMiddleWare from "../../utils/AdminMiddleware";
import { Button, Modal } from "react-bootstrap";
//import PurchaseAbonementsOnDateOfActionModalBody from "./PurchaseAbonementsOnDateOfActionModalBody";
import DateConverter from "../../utils/DateConverter";
import HistoryPurchaseAbonementsTable from "./HistoryPurchaseAbonementsTable";


export default class PurchaseAbonementsOnDateOfActionModal extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            purchaseAbonementService : new PurchaseAbonementService(),
            isLoading : false,
            isError : false,
            isSuccess : false,

            modalPurchaseAbonementsOnDateOfActionIsShowing : false,
            date_of_action : new Date(),
            purchaseAbonementLiteViewModels : [],
        }
        this.search = this.search.bind(this);
        this.modalPurchaseAbonementsOnDateOfActionOpen = this.modalPurchaseAbonementsOnDateOfActionOpen.bind(this);
        this.modalPurchaseAbonementsOnDateOfActionClose = this.modalPurchaseAbonementsOnDateOfActionClose.bind(this);
    }

    modalPurchaseAbonementsOnDateOfActionOpen(){
        this.setState({
            modalPurchaseAbonementsOnDateOfActionIsShowing : true
        });
    }

    modalPurchaseAbonementsOnDateOfActionClose(){
        this.setState({
            modalPurchaseAbonementsOnDateOfActionIsShowing : false
        });
    }

    async search(date_of_action){
        this.setState({
            date_of_action : date_of_action, 
            isLoading : true, 
            isError : false
        }, function(){
            this.modalPurchaseAbonementsOnDateOfActionOpen()
        });
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();
        
        try {
            const jsonAnswerStatus = await this.state.purchaseAbonementService.listAllLiteByDateFilter(
                jwt, 
                date_of_action, 
                date_of_action,
            );
            
            this.setState({isLoading : false});
            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.purchaseAbonementLiteViewModels !== null){
                this.setState({
                    purchaseAbonementLiteViewModels : jsonAnswerStatus.purchaseAbonementLiteViewModels
                });
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            this.setState({
                isError : true,
            });
        } finally {
            this.setState({
                isPreparing : false,
                isLoading : false,
            });
        }
    }


    render(){

        const dateConverter = new DateConverter();

        return (
            <Modal
                show={this.state.modalPurchaseAbonementsOnDateOfActionIsShowing}
                onHide={this.modalPurchaseAbonementsOnDateOfActionClose}
                animation={false}
                size="lg"
                className="modal-purchase-abonements-history"
                >
                <Modal.Header closeButton>
                    <Modal.Title className={this.state.isLoading ? "hide" : ""}>
                        Список покупок на дату {dateConverter.toDateLikedmY(this.state.date_of_action)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <HistoryPurchaseAbonementsTable
                        isLoading={this.state.isLoading}
                        isError={this.state.isError}
                        date_of_action={this.state.date_of_action}
                        purchaseAbonementLiteViewModels={this.state.purchaseAbonementLiteViewModels}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.modalPurchaseAbonementsOnDateOfActionClose}>
                        Закрыть окно
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}