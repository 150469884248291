import constant from "../utils/GlobalValues";


export default class DanceGroupService {
    

    async getScheduleByDate(jwt, date_str){
        return await fetch(constant.baseDomain + "/api/dance_group/get_schedule_by_date",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "date_str" : date_str
            })
        }).then(res => res.json());
    }

    async update(jwt, 
        dance_group_id,
        name,
        teacher_id,
        description,
        status,
        status_for_app,
        status_of_creative,
        branch_id,
        is_active_reservation,
        is_event,
        is_abonements_allow_all
    ){
        return await fetch(constant.baseDomain + "/api/dance_group/update",
        {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "dance_group_id" : dance_group_id,
                "name" : name,
                "teacher_id" : teacher_id,
                "description" : description,
                "status" : status,
                "status_for_app" : status_for_app,
                "status_of_creative" : status_of_creative,
                "branch_id" : branch_id,
                "is_active_reservation" : is_active_reservation,
                "is_event" : is_event,
                "is_abonements_allow_all" : is_abonements_allow_all
            })
        }).then(res => res.json());
    }

    async getEdit(jwt, dance_group_id){
        return await fetch(constant.baseDomain + "/api/dance_group/get_edit",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "dance_group_id" : dance_group_id
            })
        }).then(res => res.json());
    }

    async listAllEditPreviews(jwt){
        return await fetch(constant.baseDomain + "/api/dance_group/list_all_edit_previews",
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        }).then(res => res.json());
    }

    async add(jwt, name){
        return await fetch(constant.baseDomain + "/api/dance_group/add",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "name" : name
            })
        }).then(res => res.json());
    }

    async delete(jwt, dance_group_id){
        return await fetch(constant.baseDomain + "/api/dance_group/delete",
        {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "dance_group_id" : dance_group_id
            })
        }).then(res => res.json());
    }


}