import constant from "../utils/GlobalValues";



export default class TeacherSalaryService {


    async search(
        jwt,
        date_from,
        date_to,
        dance_group_id,
        teacher_id
    ){
        return await fetch(constant.baseDomain + "/api/teacher_salary/search",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "date_from" : date_from,
                "date_to" : date_to,
                "dance_group_id" : dance_group_id,
                "teacher_id" : teacher_id
            })
        }).then(res => res.json());
    }
    
    async getSearchPrepare(jwt)
    {
        return await fetch(constant.baseDomain + "/api/teacher_salary/get_search_prepare",
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        }).then(res => res.json());
    }

    async getMoreInfoById(
        jwt,
        teacher_salary_id
    ){
        return await fetch(constant.baseDomain + "/api/teacher_salary/get_more_info_by_id",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "teacher_salary_id" : teacher_salary_id
            })
        }).then(res => res.json());
    }

    async getMoreInfo(
        jwt,
        dance_group_id,
        dance_group_day_of_week_id,
        teacher_id,
        date_of_action
    ){
        return await fetch(constant.baseDomain + "/api/teacher_salary/get_more_info",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "dance_group_id" : dance_group_id,
                "dance_group_day_of_week_id" : dance_group_day_of_week_id,
                "teacher_id" : teacher_id,
                "date_of_action" : date_of_action
            })
        }).then(res => res.json());
    }

    async update(
        jwt,
        teacher_salary_id,
        name,
        value
    ){
        return await fetch(constant.baseDomain + "/api/teacher_salary/update",
        {
            method: 'PATCH',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "teacher_salary_id" : teacher_salary_id,
                "name" : name,
                "value" : value
            })
        }).then(res => res.json());
    }

    async delete(
        jwt,
        teacher_salary_id
    ){
        return await fetch(constant.baseDomain + "/api/teacher_salary/delete",
        {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "teacher_salary_id" : teacher_salary_id
            })
        }).then(res => res.json());
    }


}