import { Button, Table } from "react-bootstrap";
import { SystemErrorPage } from "../../pages/SystemError/SystemErrorPage"
import { SystemLoadingPage } from "../../pages/SystemLoading/SystemLoadingPage"
import DateConverter from "../../utils/DateConverter";



export default function DanceGroupUserVisitLessonTable(props){

    if(!props.isLaunched)return <></>;
    if(props.isLoading)return <SystemLoadingPage />;
    if(props.isError)return <SystemErrorPage tryAgain={props.tryAgain} />;

    let theadDateOfActions = "";
    const dateConverter = new DateConverter();

    if(props.dateVisitLessonViewModels.length > 0){
        theadDateOfActions = props.dateVisitLessonViewModels.map((dateVisitLessonViewModel, index) => {
            return <th key={index}>
                {dateConverter.toDateLikedm(dateVisitLessonViewModel.date_of_action)} 
                <br />
                <font>
                    {dateVisitLessonViewModel.time_from}
                </font>
            </th>
            
        });
    }

    let userVisitLessonRows = "";
    if(props.userVisitLessonViewModels.length > 0){
        userVisitLessonRows = props.userVisitLessonViewModels.map((userVisitLessonViewModel, index) => {

            const visitLessonViewModels = userVisitLessonViewModel.visitLessonViewModels;
            const visitLessonCols = props.dateVisitLessonViewModels.map((dateVisitLessonViewModel, index) => {

                const dateVisitLessonDateOfAction = new Date(dateVisitLessonViewModel.date_of_action);
                const visitLessons = visitLessonViewModels
                .filter(visitLesson => (new Date(visitLesson.date_of_action).getTime() === dateVisitLessonDateOfAction.getTime()))
                .map((visitLesson, indexVisit) => {
                    return <font key={indexVisit}>
                        {visitLesson.visit_visits_left}
                    </font>
                });

                return <td className="visit-lesson" key={index}
                onClick={() => props.visitNewModalInitCallback(
                    userVisitLessonViewModel.userMicroViewModel.id,
                    dateVisitLessonViewModel.dance_group_id,
                    dateVisitLessonViewModel.dance_group_day_of_week_id,
                    new Date(dateVisitLessonViewModel.date_of_action)
                )}
                >
                    {visitLessons}
                </td>
            });

            return <tr key={index}>
                <td>
                    {userVisitLessonViewModel.userMicroViewModel.id}
                </td>
                <td>
                    {userVisitLessonViewModel.userMicroViewModel.secondname + " " + userVisitLessonViewModel.userMicroViewModel.firstname}
                </td>

                {visitLessonCols}

                <td>
                    <Button variant="danger" size="sm" type="button"
                    onClick={() => props.connectionUserToDanceGroupDeleteCallback(userVisitLessonViewModel.userMicroViewModel.id)}
                    >Отключить от группы</Button>
                </td>
            </tr>
        });
    }

    let sumOfPriceForPurchasesByDateOfActionForLessonRows = "";
    let amountOfSumOfPriceForPurchasesByDateOfActionForLesson = 0;
    if(props.sumOfPriceForPurchasesByDateOfActionForLessonViewModels.length > 0){
        sumOfPriceForPurchasesByDateOfActionForLessonRows = props.sumOfPriceForPurchasesByDateOfActionForLessonViewModels.map((sumOfPriceForPurchasesByDateOfActionForLessonViewModel, index) => {
            amountOfSumOfPriceForPurchasesByDateOfActionForLesson += sumOfPriceForPurchasesByDateOfActionForLessonViewModel.sum;
            return <td key={index} className="visit-lesson"
            onClick={() => props.purchaseAbonementsOnDateOfActionModalInitCallback(
                sumOfPriceForPurchasesByDateOfActionForLessonViewModel.date_of_action
            )}
            >
                {sumOfPriceForPurchasesByDateOfActionForLessonViewModel.sum}
            </td>
        });
    }

    let sumOfUsersForLessonRows = "";
    let amountOfSumOfUsersForLesson = 0;
    if(props.sumOfUsersForLessonViewModels.length > 0){
        sumOfUsersForLessonRows = props.sumOfUsersForLessonViewModels.map((sumOfUsersForLessonViewModel, index) => {
            amountOfSumOfUsersForLesson += sumOfUsersForLessonViewModel.sum;
            return <td key={index} className="visit-lesson"
            onClick={() => props.teacherSalaryGetMoreInfoCallback(
                sumOfUsersForLessonViewModel.dance_group_id,
                sumOfUsersForLessonViewModel.dance_group_day_of_week_id,
                sumOfUsersForLessonViewModel.teacher_id,
                sumOfUsersForLessonViewModel.date_of_action
                )}
            >
                {sumOfUsersForLessonViewModel.sum}
            </td>
        });
    }

    let teacherSalaryForLessonRows = "";
    let amountOfTeacherSalaryForLessonRows = 0;
    if(props.teacherSalaryForLessonViewModels.length > 0){
        teacherSalaryForLessonRows = props.teacherSalaryForLessonViewModels.map((teacherSalaryForLessonViewModel, index) => {
            amountOfTeacherSalaryForLessonRows += teacherSalaryForLessonViewModel.price;
            return <td key={index} className="visit-lesson" 
            onClick={() => props.teacherSalaryGetMoreInfoCallback(
                teacherSalaryForLessonViewModel.dance_group_id,
                teacherSalaryForLessonViewModel.dance_group_day_of_week_id,
                teacherSalaryForLessonViewModel.teacher_id,
                teacherSalaryForLessonViewModel.date_of_action
            )}
            >
                {teacherSalaryForLessonViewModel.price}
            </td>
        });
    }


    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>
                        Ученик
                    </th>
                    {theadDateOfActions}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {userVisitLessonRows}

                <tr>
                    <td></td>
                    <td className="tbody-footer-title">
                        <p>Сумма с покупок за день</p>
                        <font>Щелкните на нужной дате, чтобы получить историю продаж</font>
                    </td>

                    {sumOfPriceForPurchasesByDateOfActionForLessonRows}

                    <td className="td-amount-of-sum">
                        Итого: {amountOfSumOfPriceForPurchasesByDateOfActionForLesson}
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td className="tbody-footer-title">
                        <p>Количество учеников</p>
                        <font>Щелкните на нужной дате, чтобы получить список учеников</font>
                    </td>

                    {sumOfUsersForLessonRows}

                    <td className="td-amount-of-sum">
                        Итого: {amountOfSumOfUsersForLesson}
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td className="tbody-footer-title">
                        <p>Зарплата педагогу</p>
                        <font>Щелкните на нужной дате, чтобы получить подробную информацию</font>
                    </td>

                    {teacherSalaryForLessonRows}

                    <td className="td-amount-of-sum">
                        Итого: {amountOfTeacherSalaryForLessonRows}
                    </td>
                </tr>
            </tbody>
        </Table>
    )

}