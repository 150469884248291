import { SystemErrorPage } from "../../pages/SystemError/SystemErrorPage"
import { SystemLoadingPage } from "../../pages/SystemLoading/SystemLoadingPage"



export default function ConnectionUserToDanceGroupNewModalBody(props){

    if(!props.isLaunched)return <></>
    if(props.isLoading)return <SystemLoadingPage />
    if(props.isError)return <SystemErrorPage tryAgain={props.tryAgainCallback} />

    let userMicros = "";
    if(props.userMicroViewModels.length > 0){
        userMicros = props.userMicroViewModels.map((userMicroViewModel, index) => {
            return <div key={index} className="connection-user-to-dance-group-block"
            onClick={() => props.addNewCallback(userMicroViewModel.id)}>
                <p>
                    {userMicroViewModel.secondname + " " + userMicroViewModel.firstname} <font>id{userMicroViewModel.id}</font>
                </p>
            </div>
        });
    } else {
        userMicros = <center><i>- записей не найдено -</i></center>
    }

    return userMicros;
    
}