import { Component } from "react";
import UserService from "../../service/UserService";
import AdminMiddleWare from "../../utils/AdminMiddleware";
import { Button, Modal } from "react-bootstrap";


export default class UserDeleteModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading : false,
            isError : false,
            
            userService : new UserService(),
            modalUserDeleteIsShowing : false,
            user_id : 0,
        }
        this.modalUserDeleteOpen = this.modalUserDeleteOpen.bind(this);
        this.modalUserDeleteClose = this.modalUserDeleteClose.bind(this);
        this.prepare = this.prepare.bind(this);
        this.delete = this.delete.bind(this);
    }

    modalUserDeleteOpen(){
        this.setState({
            modalUserDeleteIsShowing : true
        });
    }

    modalUserDeleteClose(){
        this.setState({
            modalUserDeleteIsShowing : false
        });
    }

    prepare(user_id){
        this.setState({
            user_id : user_id
        }, function(){
            this.modalUserDeleteOpen();
        });
    }

    async delete(){
        this.setState({
            isLoading : true, 
            isError : false,
        });

        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();
        
        try {
            const jsonAnswerStatus = await this.state.userService.delete(
                jwt, 
                this.state.user_id
            );
            
            if(jsonAnswerStatus.status === "success" ){
                this.modalUserDeleteClose();
                this.props.modalUserDeleteSuccessCallback();
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            alert("Неизвестная ошибка на сервере");
        } finally {
            this.setState({isLoading : false});
        }
    }

    render(){
        return (
            <Modal
                show={this.state.modalUserDeleteIsShowing}
                onHide={this.modalUserDeleteClose}
                animation={false}
                size="lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Удаления пользователя
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Вы уверены, что хотите удалить клиента?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.delete} disabled={this.state.isLoading}>
                        Удалить
                    </Button>
                    <Button variant="secondary" onClick={this.modalUserDeleteClose}>
                        Закрыть окно
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}