
import { Button, Form } from "react-bootstrap";


export default function PurchaseAbonementNewModalBody(props){

    /*
    const refPriceInput = useRef(null);
    useEffect(() => {
        refPriceInput.current.focus();
    })
    */

    return (
        <Form>
            <Form.Group>
                <Form.Label>Наличными</Form.Label>
                <Form.Control name="price" type="number" placeholder="Наличными" 
                    //ref={refPriceInput} 
                    defaultValue={props.price}
                    onChange={props.formEditAbonementBuyListener}
                    onKeyPress={props.formKeyPressAbonementBuyListenerCallback}
                />
                <Button className="info" size="sm" onClick={props.formEditAbonementOnlyCashListener}>только нал</Button>
            </Form.Group>
            <Form.Group>
                <Form.Label>Безналичный способ оплаты:</Form.Label>
                <Form.Control name="cashless" type="number" placeholder="Безналичные"  
                    defaultValue={props.cashless}
                    onChange={props.formEditAbonementBuyListener}
                    onKeyPress={props.formKeyPressAbonementBuyListenerCallback}
                />
                <Button className="info" size="sm" onClick={props.formEditAbonementOnlyCashlessListener}>только безнал</Button>
            </Form.Group>

            <hr />

            <Form.Group>
                <Form.Label>Количество занятий:</Form.Label>
                <Form.Control name="visits" type="number" placeholder="Количество занятий" 
                    disabled={props.special_status === "raz"} 
                    defaultValue={props.visits}
                    onChange={props.formEditAbonementBuyListener}
                    onKeyPress={props.formKeyPressAbonementBuyListenerCallback}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Дней действует с момента активации:</Form.Label>
                <Form.Control name="days" type="number" placeholder="Дней действует с момента активации"  
                    disabled={props.special_status === "raz"} 
                    defaultValue={props.days}
                    onChange={props.formEditAbonementBuyListener}
                    onKeyPress={props.formKeyPressAbonementBuyListenerCallback}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Комментарий:</Form.Label>
                <Form.Control name="comment" as="textarea" rows={5} placeholder="Комментарий"  
                    onChange={props.formEditAbonementBuyListener}
                    onKeyPress={props.formKeyPressAbonementBuyListenerCallback}
                />
            </Form.Group>

        </Form>
    )
}