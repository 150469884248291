import { Component } from "react";
import AdminService from "../../service/AdminService";
import { Button, Modal } from "react-bootstrap";
import AdminEditModalBody from "./AdminEditModalBody";
import AdminMiddleWare from "../../utils/AdminMiddleware";


export default class AdminEditModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading : false,
            isError : false,
            isSaving : false,
            isLaunched : false,

            adminService : new AdminService(),
            modalAdminEditIsShowing : false,
            admin_id : 0,

            username : "",
            firstname : "",
            password_new : "",
            active : 0,
            date_of_add : "",
            date_of_last_update_profile : "",
            
            panel_admins : 0,
            panel_lessons : 0,
            panel_users : 0,
            panel_dance_groups : 0,
            panel_teachers : 0,
            panel_teacher_salaries : 0,
            panel_abonements : 0,
            panel_discounts : 0,
            panel_cansels : 0,
            panel_branches : 0,
            panel_cashbox : 0,
            panel_surveillance : 0,

            warning : "",
            success : "",
        }
        this.modalAdminEditOpen = this.modalAdminEditOpen.bind(this);
        this.modalAdminEditClose = this.modalAdminEditClose.bind(this);
        this.formAdminEditListener = this.formAdminEditListener.bind(this);
        this.getForEdit = this.getForEdit.bind(this);
        this.update = this.update.bind(this);
        this.clearWarning = this.clearWarning.bind(this);
        this.modalAdminDeletePrepare = this.modalAdminDeletePrepare.bind(this);
    }

    modalAdminEditOpen(){
        this.setState({
            modalAdminEditIsShowing : true
        });
    }

    modalAdminEditClose(){
        this.setState({
            modalAdminEditIsShowing : false
        });
    }

    formAdminEditListener(e){
        this.clearWarning();
        switch(e.target.name){
            case "username":
                this.setState({username : e.target.value});
                break;
            case "firstname":
                this.setState({firstname : e.target.value});
                break;
            case "password_new":
                this.setState({password_new : e.target.value});
                break;
            case "active":
                this.setState({active : e.target.value});
                break;
            case "panel_admins":
                this.setState({panel_admins : e.target.value});
                break;
            case "panel_lessons":
                this.setState({panel_lessons : e.target.value});
                break;
            case "panel_users":
                this.setState({panel_users : e.target.value});
                break;
            case "panel_dance_groups":
                this.setState({panel_dance_groups : e.target.value});
                break;
            case "panel_teachers":
                this.setState({panel_teachers : e.target.value});
                break;
            case "panel_teacher_salaries":
                this.setState({panel_teacher_salaries : e.target.value});
                break;
            case "panel_abonements":
                this.setState({panel_abonements : e.target.value});
                break;
            case "panel_discounts":
                this.setState({panel_discounts : e.target.value});
                break;
            case "panel_cansels":
                this.setState({panel_cansels : e.target.value});
                break;
            case "panel_branches":
                this.setState({panel_branches : e.target.value});
                break;
            case "panel_cashbox":
                this.setState({panel_cashbox : e.target.value});
                break;
            case "panel_surveillance":
                this.setState({panel_surveillance : e.target.value});
                break;
            default:
                break;
        }
    }

    async getForEdit(admin_id){
        this.setState({
            isLoading : true,
            isError : false,
            isLaunched : true,
            admin_id : admin_id,
            username : "",
            firstname : "",
            password_new : "",
            warning : "",
            success : "",
        }, function(){
            this.modalAdminEditOpen();
        });
        
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        try {
            const jsonAnswerStatus = await this.state.adminService.getForEdit(jwt, admin_id);
            
            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.adminProfileViewModel !== null){


                this.setState({
                    username : jsonAnswerStatus.adminProfileViewModel.username,
                    firstname : jsonAnswerStatus.adminProfileViewModel.firstname,
                    active : jsonAnswerStatus.adminProfileViewModel.active,
                    date_of_add : jsonAnswerStatus.adminProfileViewModel.date_of_add,
                    date_of_last_update_profile : jsonAnswerStatus.adminProfileViewModel.date_of_last_update_profile,
                    panel_admins : jsonAnswerStatus.adminProfileViewModel.panel_admins,
                    panel_lessons : jsonAnswerStatus.adminProfileViewModel.panel_lessons,
                    panel_users : jsonAnswerStatus.adminProfileViewModel.panel_users,
                    panel_dance_groups : jsonAnswerStatus.adminProfileViewModel.panel_dance_groups,
                    panel_teachers : jsonAnswerStatus.adminProfileViewModel.panel_teachers,
                    panel_teacher_salaries : jsonAnswerStatus.adminProfileViewModel.panel_teacher_salaries,
                    panel_abonements : jsonAnswerStatus.adminProfileViewModel.panel_abonements,
                    panel_discounts : jsonAnswerStatus.adminProfileViewModel.panel_discounts,
                    panel_cansels : jsonAnswerStatus.adminProfileViewModel.panel_cansels,
                    panel_branches : jsonAnswerStatus.adminProfileViewModel.panel_branches,
                    panel_cashbox : jsonAnswerStatus.adminProfileViewModel.panel_cashbox,
                    panel_surveillance : jsonAnswerStatus.adminProfileViewModel.panel_surveillance,
                });
            } else {
                this.setState({
                    isError : true
                });
            }
        } catch(e){
            this.setState({
                isError : true
            });
        } finally {
            this.setState({
                isLoading : false,
                isSaving : false,
            });
        }
    }

    clearWarning(){
        this.setState({
            warning : "",
            success : ""
        });
    }
    async update(){
        this.clearWarning();
        this.setState({
            isSaving : true,
            isError : false
        });
        
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        try {
            const jsonAnswerStatus = await this.state.adminService.update(
                jwt, 
                this.state.admin_id,
                this.state.username,
                this.state.firstname,
                this.state.password_new,
                this.state.active,
                this.state.panel_admins,
                this.state.panel_lessons,
                this.state.panel_users,
                this.state.panel_dance_groups,
                this.state.panel_teachers,
                this.state.panel_teacher_salaries,
                this.state.panel_abonements,
                this.state.panel_discounts,
                this.state.panel_cansels,
                this.state.panel_branches,
                this.state.panel_cashbox,
                this.state.panel_surveillance,
            );
            
            if(jsonAnswerStatus.status === "success"){
                this.setState({
                    success : "Успешно сохранено",
                }, function(){
                    this.props.adminUpdateSuccessCallback();
                });
            } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "username_already_exist"){
                this.setState({
                    warning : "Логин уже используется в базе",
                });
            }  else {
                this.setState({
                    warning : "Неизвестная ошибка на сервере",
                });
            }
        } catch(e){
            this.setState({
                warning : "Неизвестная ошибка на сервере",
            });
        } finally {
            this.setState({
                isLoading : false,
                isSaving : false
            });
        }
    }

    modalAdminDeletePrepare(admin_id){
        this.modalAdminEditClose();
        this.props.modalAdminDeletePrepareCallback(admin_id);
    }

    render(){
        return (
            <Modal
                show={this.state.modalAdminEditIsShowing}
                onHide={this.modalAdminEditClose}
                animation={false}
                size="lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Редактирование администратора
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <AdminEditModalBody
                        isLoading={this.state.isLoading}
                        isError={this.state.isError}
                        isSaving={this.state.isSaving}
                        admin_id={this.state.admin_id}

                        username={this.state.username}
                        firstname={this.state.firstname}
                        password={this.state.password}
                        active={this.state.active}
                        date_of_add={this.state.date_of_add}
                        date_of_last_update_profile={this.state.date_of_last_update_profile}

                        panel_admins={this.state.panel_admins}
                        panel_lessons={this.state.panel_lessons}
                        panel_users={this.state.panel_users}
                        panel_dance_groups={this.state.panel_dance_groups}
                        panel_teachers={this.state.panel_teachers}
                        panel_teachers_salaries={this.state.panel_teachers_salaries}
                        panel_abonements={this.state.panel_abonements}
                        panel_discounts={this.state.panel_discounts}
                        panel_cansels={this.state.panel_discounts}
                        panel_branches={this.state.panel_branches}

                        formAdminEditListener={this.formAdminEditListener}
                        tryAgain={() => this.getForEdit(this.state.admin_id)}
                        modalAdminDeletePrepareCallback={this.modalAdminDeletePrepare}
                    />

                    <p className="warning">{this.state.warning}</p>
                    <p className="success">{this.state.success}</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.update} disabled={this.state.isSaving || this.state.isLoading}>
                        Сохранить
                    </Button>
                    <Button variant="secondary" onClick={this.modalAdminEditClose}>
                        Закрыть окно
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}