import { Component } from "react";
import AdminMiddleWare from "../../utils/AdminMiddleware";
import UserService from "../../service/UserService";
import { Button, Form, Modal } from "react-bootstrap";
import ConnectionUserToDanceGroupNewModalBody from "./ConnectionUserToDanceGroupNewModalBody";
import ConnectionUserToDanceGroupService from "../../service/ConnectionUserToDanceGroupService";


export default class ConnectionUserToDanceGroupNewModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLaunched : false,
            isLoading : false,
            isError: false,
            warning : "",

            userService : new UserService(),
            connectionUserToDanceGroupService: new ConnectionUserToDanceGroupService(),
            queryString : "",
            user_id : 0,
            dance_group_id : 0,
            date_of_action : new Date(),
            userMicroViewModels : [],
            
            modalConnectionUserToDanceGroupNewIsShowing : false,
        }
        this.formSearchListener = this.formSearchListener.bind(this);
        this.modalConnectionUserToDanceGroupNewOpen = this.modalConnectionUserToDanceGroupNewOpen.bind(this);
        this.modalConnectionUserToDanceGroupNewClose = this.modalConnectionUserToDanceGroupNewClose.bind(this);
        this.search = this.search.bind(this);
        this.prepare = this.prepare.bind(this);
        this.addNew = this.addNew.bind(this);
    }

    modalConnectionUserToDanceGroupNewOpen(){
        this.setState({
            modalConnectionUserToDanceGroupNewIsShowing : true
        });
    }

    modalConnectionUserToDanceGroupNewClose(){
        this.setState({
            modalConnectionUserToDanceGroupNewIsShowing : false
        });
    }

    formSearchListener(e){
        switch(e.target.name){
            case "query_string":
                this.setState({queryString : e.target.value});
                break;
            default:
                break;
        }
    }

    async prepare(dance_group_id, date_of_action){
        this.setState({
            isLaunched : false,
            queryString : "",
            user_id : 0,
            dance_group_id : dance_group_id,
            date_of_action : date_of_action,
            userMicroViewModels : [],
        }, function(){
            this.modalConnectionUserToDanceGroupNewOpen();
        });
    }

    async search(){
        if(this.state.queryString === ""){
            this.setState({
                userMicroViewModels : [],
            });
            return;
        }
        
        this.setState({isLoading : true, isError : false, isLaunched : true});
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        try{
            const jsonAnswerStatus = await this.state.userService.searchMicro(
                jwt, 
                this.state.queryString
            );
            
            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.userMicroViewModels !== null){
                this.setState({
                    userMicroViewModels : jsonAnswerStatus.userMicroViewModels
                });
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            this.setState({
                isError : true
            });
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    async addNew(userId){
        this.setState({isLoading : true, isError : false, isLaunched : true});
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        try{
            const jsonAnswerStatus = await this.state.connectionUserToDanceGroupService.update(
                jwt,
                userId,
                this.state.dance_group_id,
                true
            );
            
            if(jsonAnswerStatus.status === "success"){
                this.modalConnectionUserToDanceGroupNewClose();
                this.props.connectionUserToDanceGroupNewSuccessCallback();
            } else {
                alert("Неизвестная ошибка на сервере");
            }
        } catch(e){
            this.setState({
                isError : true
            });
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    render(){
        return (
            <Modal
                show={this.state.modalConnectionUserToDanceGroupNewIsShowing}
                onHide={this.modalConnectionUserToDanceGroupNewClose}
                animation={false}
                size="lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title >
                        Подключить ученика к группе
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group>
                            <Form.Label>Найти первые 20 записей</Form.Label>
                            <Form.Control name="query_string" type="text" placeholder="Поиск"
                                onChange={this.formSearchListener}
                            />
                        </Form.Group>
                        <Button variant="success" type="button"
                        disabled={this.state.isLoading}
                        onClick={this.search}
                        >Поиск</Button>
                    </Form>

                    <ConnectionUserToDanceGroupNewModalBody
                    isLoading={this.state.isLoading}
                    isError={this.state.isError}
                    isLaunched={this.state.isLaunched}
                    tryAgain={this.search}
                    userMicroViewModels={this.state.userMicroViewModels}
                    addNewCallback={this.addNew}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.modalConnectionUserToDanceGroupNewClose}>
                        Закрыть окно
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}