import React, { Component } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DateConverter from "../utils/DateConverter";
import AdminMiddleWare from "../utils/AdminMiddleware";
import SurveillanceService from "../service/SurveillanceService";
import { SystemLoadingPage } from "./SystemLoading/SystemLoadingPage";
import { SystemErrorPage } from "./SystemError/SystemErrorPage";



class Surveillances extends Component {

    constructor(props){
        super(props);
        const dateConverter = new DateConverter();
        this.state = {
            isLaunched : false,
            isLoading : false,
            isError: false,
            isSearching : false,

            surveillanceService : new SurveillanceService(),

            date_from : dateConverter.toStringLikeYmd(new Date()),
            date_to :  dateConverter.toStringLikeYmd(new Date()),
            surveillanceLiteViewModels : [],
        }
        this.filterListener = this.filterListener.bind(this);
        this.search = this.search.bind(this);
    }



    filterListener(e){
        switch(e.target.name){
            case "date_from":
                this.setState({date_from : e.target.value});
                break;
            case "date_to":
                this.setState({date_to : e.target.value});
                break;
            case "dance_group_id":
                this.setState({dance_group_id : parseInt(e.target.value, 10)});
                break;
            case "teacher_id":
                this.setState({teacher_id :  parseInt(e.target.value, 10)});
                break;
            default:
                break;
        }
    }


    async search(){
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        this.setState({
            isLaunched : true,
            isLoading: true,
            isError: false
        });
        
        try {
            const jsonAnswerStatus = await this.state.surveillanceService.search(
                jwt,
                this.state.date_from,
                this.state.date_to
            );
            //console.log(jsonAnswerStatus);

            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.surveillanceLiteViewModels !== null){
                this.setState({
                    surveillanceLiteViewModels : jsonAnswerStatus.surveillanceLiteViewModels
                });
            } else {
                this.setState({
                    isError : true
                });
            }
        } catch(e){
            this.setState({isError : true});
        } finally {
            this.setState({isLoading : false});
        }
    }


    render(){

        let surveillancesTable = "";
        if(!this.state.isLaunched) {
            surveillancesTable = "";
        } else if(this.state.isLoading){
            surveillancesTable = <SystemLoadingPage />
        } else if(this.state.isError){
            surveillancesTable = <SystemErrorPage tryAgain={this.search} />
        } else if(this.state.surveillanceLiteViewModels.length === 0){
            surveillancesTable = <center><i>- записей не найдено -</i></center>
        } else {

            const dateConverter = new DateConverter();
            const surveillanceLiteRows = this.state.surveillanceLiteViewModels.map((surveillanceLiteViewModel) => {

                const dateOfAdd = surveillanceLiteViewModel.date_of_add !== null ? dateConverter.toDateLikedmYis(surveillanceLiteViewModel.date_of_add) : "<ошибка даты>";
                const dateOfAction = surveillanceLiteViewModel.date_of_action !== null ? dateConverter.toDateLikedmY(surveillanceLiteViewModel.date_of_action) : "<ошибка даты>";

                const danceGroupMicroViewModel = surveillanceLiteViewModel.danceGroupMicroViewModel;
                const danceGroupDayOfWeekLiteViewModel = surveillanceLiteViewModel.danceGroupDayOfWeekLiteViewModel;
                //console.log("danceGroupDayOfWeekLiteViewModel: " + danceGroupDayOfWeekLiteViewModel);

                let danceGroupLessonName = danceGroupMicroViewModel !== null ? "id" + danceGroupMicroViewModel.id + " " + danceGroupMicroViewModel.name : "";
                if(danceGroupDayOfWeekLiteViewModel !== null){
                    danceGroupLessonName += " (" + danceGroupDayOfWeekLiteViewModel.time_from + "-" + danceGroupDayOfWeekLiteViewModel.time_to + ")"
                }

                return <tr
                    key={surveillanceLiteViewModel.id}
                >
                    <td>{surveillanceLiteViewModel.id}</td>
                    <td>{dateOfAdd}</td>
                    <td>{surveillanceLiteViewModel.action_name}</td>
                    <td>{surveillanceLiteViewModel.adminMicroViewModel !== null ? "id" + surveillanceLiteViewModel.adminMicroViewModel.id + " " + surveillanceLiteViewModel.adminMicroViewModel.firstname : "-"}</td>
                    <td>{surveillanceLiteViewModel.userMicroViewModel !== null ? "id" + surveillanceLiteViewModel.userMicroViewModel.id + " " + surveillanceLiteViewModel.userMicroViewModel.secondname + " " + surveillanceLiteViewModel.userMicroViewModel.firstname : "<пользователь не найден>"}</td>
                    <td>{dateOfAction}</td>
                    <td>{surveillanceLiteViewModel.abonementLiteViewModel !== null ? "id" + surveillanceLiteViewModel.abonementLiteViewModel.id + " " + surveillanceLiteViewModel.abonementLiteViewModel.name : ""}</td>
                    <td>{danceGroupLessonName}</td>
                    <td>{surveillanceLiteViewModel.cash}</td>
                    <td>{surveillanceLiteViewModel.cashless}</td>
                </tr>
            });

            surveillancesTable = <Table striped bordered hover>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Дата перехвата</th>
                    <th>Наименование</th>
                    <th>Администратор</th>
                    <th>Пользователь</th>
                    <th>Дата операции</th>
                    <th>Абонемент</th>
                    <th>Группа</th>
                    <th>Наличные</th>
                    <th>Безналичные</th>
                </tr>
            </thead>
            <tbody>
                {surveillanceLiteRows}
            </tbody>
        </Table>
        }

        return(
            <div className="row page teacher-salaries">
                <div className="col-12 row filter-block">
                    <div className="col-12">
                        <h5>Для получения списка операций выберите пожалуйста даты</h5>
                    </div>
                    <div className="col-12 col-lg-8 col-md-12 col-sm-12 div-form row">
                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilterDateFrom">
                                    <Form.Label>С даты:</Form.Label>
                                    <Form.Control type="date" name="date_from" defaultValue={this.state.date_from} 
                                    onChange={this.filterListener}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                            <Form>
                                <Form.Group controlId="formFilterDateTo">
                                    <Form.Label>По дату:</Form.Label>
                                    <Form.Control type="date" name="date_to" defaultValue={this.state.date_to} 
                                    onChange={this.filterListener}
                                    />
                                </Form.Group>
                            </Form>
                        </div>


                        <div className="col-12 text-right">
                            <Button variant="success" type="button" 
                            onClick={this.search} disabled={this.state.isLoading}
                            >
                                Поиск
                            </Button>
                        </div>

                    </div>
                    
                </div>

                <div className="col-12">
                    {surveillancesTable}
                </div>


            </div>
        )
    }

}


export default function SurveillancesPage(props){
    const navigate = useNavigate();
    return(<Surveillances navigate={navigate}></Surveillances>)
}