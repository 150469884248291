
import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import AbonementService from "../../service/AbonementService";
import PurchaseAbonementNewModalBody from "./PurchaseAbonementNewModalBody";
import AdminMiddleWare from "../../utils/AdminMiddleware";
import { SystemLoadingPage } from "../../pages/SystemLoading/SystemLoadingPage";
import { SystemErrorPage } from "../../pages/SystemError/SystemErrorPage";
import PurchaseAbonementService from "../../service/PurchaseAbonementService";


export default class PurchaseAbonementNewModal extends Component {


    constructor(props){
        super(props);
        this.state = {
            abonementService : new AbonementService(),
            purchaseAbonementService : new PurchaseAbonementService(),
            isLoading : false,
            isError : false,
            isSuccess : false,

            modalPurchaseAbonementNewIsShowing : false,
            user_id : 0,
            abonement_id : 0,
            dance_group_id : 0,
            dance_group_day_of_week_id : 0,
            date_of_buy : "",

            modalPurchaseAbonementNewRandomKey : 0,
            abonementPrice : 0,
            purchaseAbonementNewName : "",
            purchaseAbonementNewSpecialStatus : 0,
            purchaseAbonementNewPrice : 0,
            purchaseAbonementNewCashless : 0,
            purchaseAbonementNewVisits : 0,
            purchaseAbonementNewDays : 0,
            purchaseAbonementNewComment : "",
        }
        this.getForBuy = this.getForBuy.bind(this);
        this.formEditAbonementBuyListener = this.formEditAbonementBuyListener.bind(this);
        this.formKeyPressAbonementBuyListener = this.formKeyPressAbonementBuyListener.bind(this);
        this.formEditAbonementOnlyCashListener = this.formEditAbonementOnlyCashListener.bind(this);
        this.formEditAbonementOnlyCashlessListener = this.formEditAbonementOnlyCashlessListener.bind(this);
        this.purchaseAbonementNewInit = this.purchaseAbonementNewInit.bind(this);
        this.modalPurchaseAbonementNewOpen = this.modalPurchaseAbonementNewOpen.bind(this);
        this.modalPurchaseAbonementNewClose = this.modalPurchaseAbonementNewClose.bind(this);
    }

    modalPurchaseAbonementNewOpen(){
        this.setState({
            modalPurchaseAbonementNewIsShowing : true
        });
    }

    modalPurchaseAbonementNewClose(){
        this.setState({
            modalPurchaseAbonementNewIsShowing : false
        });
    }

    async getForBuy(user_id, abonement_id, dance_group_id, dance_group_day_of_week_id, date_of_buy){
        
        this.setState({
            user_id : user_id,
            abonement_id : abonement_id, 
            dance_group_id : dance_group_id, 
            dance_group_day_of_week_id : dance_group_day_of_week_id, 
            date_of_buy : date_of_buy, 
            isLoading : true, 
            isError : false,
            isSuccess : false
        }, function(){
            this.modalPurchaseAbonementNewOpen()
        });

        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();
        
        try {
            const jsonAnswerStatus = await this.state.abonementService.getForBuy(jwt, abonement_id);
            
            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.abonementForBuyViewModel !== null){
                this.setState({
                    abonementPrice : jsonAnswerStatus.abonementForBuyViewModel.price,
                    purchaseAbonementNewName : jsonAnswerStatus.abonementForBuyViewModel.name,
                    purchaseAbonementNewSpecialStatus : jsonAnswerStatus.abonementForBuyViewModel.special_status,
                    purchaseAbonementNewPrice : parseInt(jsonAnswerStatus.abonementForBuyViewModel.price, 10),
                    purchaseAbonementNewCashless : 0,
                    purchaseAbonementNewVisits : parseInt(jsonAnswerStatus.abonementForBuyViewModel.visits, 10),
                    purchaseAbonementNewDays : parseInt(jsonAnswerStatus.abonementForBuyViewModel.days, 10),
                    purchaseAbonementNewComment : ""
                });
            } else {
                this.setState({
                    isError : true
                });
            }
        } catch(e){
            this.setState({
                isError : true,
            });
        } finally {
            this.setState({
                isLoading : false,
            });
        }
    }

    formKeyPressAbonementBuyListener(e){
        if(e.key === "Enter"){
            this.purchaseAbonementNewInit();
        }
    }
    formEditAbonementOnlyCashListener(){
        //console.log("formEditAbonementOnlyCashListener");
        this.setState({
            purchaseAbonementNewPrice : this.state.abonementPrice,
            purchaseAbonementNewCashless : 0,
            modalPurchaseAbonementNewRandomKey : Math.floor(Math.random() * 10000)
        }, function(){
            console.log("purchaseAbonementNewPrice: " + this.state.purchaseAbonementNewPrice)
        });
    }
    formEditAbonementOnlyCashlessListener(){
        this.setState({
            purchaseAbonementNewPrice : 0,
            purchaseAbonementNewCashless : this.state.abonementPrice,
            modalPurchaseAbonementNewRandomKey : Math.floor(Math.random() * 10000)
        }, function(){
            console.log("purchaseAbonementNewPrice: " + this.state.purchaseAbonementNewPrice)
        });
    }
    formEditAbonementBuyListener(e){
        switch(e.target.name){
            case "price":
                this.setState({purchaseAbonementNewPrice : parseInt(e.target.value, 10)});
                break;
            case "cashless":
                this.setState({purchaseAbonementNewCashless : parseInt(e.target.value, 10)});
                break;
            case "visits":
                this.setState({purchaseAbonementNewVisits : parseInt(e.target.value, 10)});
                break;
            case "days":
                this.setState({purchaseAbonementNewDays : parseInt(e.target.value, 10)});
                break;
            case "comment":
                this.setState({purchaseAbonementNewComment : e.target.value});
                break;
            default:
                break;
        }
    }

    async purchaseAbonementNewInit(){
        this.setState({isLoading : true, isError : false});

        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();
        
        console.log("this.state.user_id: " + this.state.user_id);
        
        try {
            const jsonAnswerStatus = await this.state.purchaseAbonementService.add(
                jwt, 
                this.state.user_id,
                this.state.abonement_id,
                this.state.dance_group_id,
                this.state.dance_group_day_of_week_id,
                this.state.purchaseAbonementNewPrice,
                this.state.purchaseAbonementNewCashless,
                this.state.purchaseAbonementNewVisits,
                this.state.purchaseAbonementNewDays,
                this.state.purchaseAbonementNewComment,
                this.state.date_of_buy
            );
            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.abonementForBuyViewModel !== null){
                this.modalPurchaseAbonementNewClose();
                this.props.modalPurchaseAbonementNewSuccessCallback();
            } else {
                this.setState({
                    isError : true
                });
            }
        } catch(e){
            this.setState({
                isError : true,
            });
        } finally {
            this.setState({
                isLoading : false,
            });
        }
    }

    render(){

        let purchaseAbonementNewModalBody = "";
        if(this.state.isLoading){
            purchaseAbonementNewModalBody = <SystemLoadingPage />;
        } else if(this.state.isError){
            purchaseAbonementNewModalBody = <SystemErrorPage tryAgain={() => this.getForBuy(this.state.user_id, this.state.abonement_id)} />;
        } else if(this.state.isSuccess){
            purchaseAbonementNewModalBody = 
            <div className="row system-success">
                <div className="col-4 d-none d-md-block"></div>
                <div className="col-12 col-lg-4 col-md-4">
                    <p>Запрос успешно выполнен</p>
                </div>
            </div>
        } else {
            purchaseAbonementNewModalBody = <PurchaseAbonementNewModalBody
                key={this.state.modalPurchaseAbonementNewRandomKey}
                special_status={this.state.purchaseAbonementNewSpecialStatus}
                price={this.state.purchaseAbonementNewPrice}
                cashless={this.state.purchaseAbonementNewCashless}
                visits={this.state.purchaseAbonementNewVisits}
                days={this.state.purchaseAbonementNewDays}
                comment={this.state.purchaseAbonementNewComment}
                formEditAbonementBuyListener={this.formEditAbonementBuyListener}
                formKeyPressAbonementBuyListenerCallback={this.formKeyPressAbonementBuyListener}
                formEditAbonementOnlyCashListener={this.formEditAbonementOnlyCashListener}
                formEditAbonementOnlyCashlessListener={this.formEditAbonementOnlyCashlessListener}
            />
        }

        return (
            <Modal
                show={this.state.modalPurchaseAbonementNewIsShowing}
                onHide={this.modalPurchaseAbonementNewClose}
                animation={false}
                size="lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title className={this.state.isLoading ? "hide" : ""}>
                        Оформление покупки "{this.state.purchaseAbonementNewName}"
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {purchaseAbonementNewModalBody}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={this.purchaseAbonementNewInit} className={this.state.isSuccess ? "hide" : ""} disabled={this.state.isLoading}>
                        Купить
                    </Button>
                    <Button variant="secondary" onClick={this.modalPurchaseAbonementNewClose} disabled={this.state.isLoading}>
                        Закрыть окно
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}