import constant from "../utils/GlobalValues";


export default class AnalyticsService {

    async dashboard(
        jwt,
        date_from,
        date_to
    ){
        return await fetch(constant.baseDomain + "/api/analytics/dashboard",
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer '+ jwt, 
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                "date_from" : date_from,
                "date_to" : date_to
            })
        }).then(res => res.json());
    }

}