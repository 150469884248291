
import imgUserDefault from "../../assets/images/user.png";

export default function AdminSearchPreviewRow(props){


    return (
        <div className="col-12">
            <div className="admin-search-preview" onClick={() => props.getAdminCallback(props.id)}>
                <img src={imgUserDefault} className="img-fluid" alt="user-poster" />
                <p>{props.username} id{props.id}</p>
                
                <ul className="short-info">
                    <li><i className="fa fa-user"></i> {props.firstname}</li>
                    <li className={props.active === 1 ? "success" : "warning"}><i className="fa fa-sign-in"></i> {props.active === 1 ? "Доступ открыт" : "Доступ закрыт"}</li>
                </ul>
            </div>
        </div>
    )
}