import { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DateConverter from "../utils/DateConverter";
import AdminMiddleWare from "../utils/AdminMiddleware";
import AnalyticsService from "../service/AnalyticsService";
import { SystemLoadingPage } from "./SystemLoading/SystemLoadingPage";
import { SystemErrorPage } from "./SystemError/SystemErrorPage";
import DashboardPanel from "../components/Dashboard/DashboardPanel";


class Dashboard extends Component {

    constructor(props){
        super(props)
        const dateConverter = new DateConverter();
        this.state = {
            isLoading : false,
            date_from : dateConverter.toStringLikeYmd(new Date()),
            date_to :  dateConverter.toStringLikeYmd(new Date()),
            purchase_abonement_cash_amount : 0,
            purchase_abonement_cashless_amount : 0,
            purchase_abonement_count : 0,
            teacher_salary_amount : 0,
            teacher_salary_count : 0,
            users_new_count : 0,
            visits_count : 0,
            purchase_abonement_usual_amount : 0,
            purchase_abonement_usual_count : 0,
            purchase_abonement_raz_amount : 0,
            purchase_abonement_raz_count : 0,
            profit : 0,
            danceGroupMicroAnalyticsViewModels : [],
        }
        this.filterListener = this.filterListener.bind(this);
        this.search = this.search.bind(this);
    }

    async componentDidMount(){
        await this.search();
    }

    filterListener(e){
        switch(e.target.name){
            case "date_from":
                this.setState({date_from : e.target.value});
                break;
            case "date_to":
                this.setState({date_to : e.target.value});
                break;
            default:
                break;
        }
    }

    async search(){
        const adminMiddleWare = new AdminMiddleWare();
        const jwt = adminMiddleWare.getJWTFromCookie();

        this.setState({
            isLaunched : true,
            isLoading: true,
            isError: false
        });
        try {
            const analyticsService = new AnalyticsService();
            const jsonAnswerStatus = await analyticsService.dashboard(
                jwt,
                this.state.date_from,
                this.state.date_to
            );
            //console.log(jsonAnswerStatus);

            if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.analyticsDashboardSearchViewModel !== null){
                this.setState({
                    purchase_abonement_cash_amount : jsonAnswerStatus.analyticsDashboardSearchViewModel.purchase_abonement_cash_amount,
                    purchase_abonement_cashless_amount : jsonAnswerStatus.analyticsDashboardSearchViewModel.purchase_abonement_cashless_amount,
                    purchase_abonement_count : jsonAnswerStatus.analyticsDashboardSearchViewModel.purchase_abonement_count,
                    teacher_salary_amount : jsonAnswerStatus.analyticsDashboardSearchViewModel.teacher_salary_amount,
                    teacher_salary_count : jsonAnswerStatus.analyticsDashboardSearchViewModel.teacher_salary_count,
                    users_new_count : jsonAnswerStatus.analyticsDashboardSearchViewModel.users_new_count,
                    visits_count : jsonAnswerStatus.analyticsDashboardSearchViewModel.visits_count,
                    purchase_abonement_usual_amount : jsonAnswerStatus.analyticsDashboardSearchViewModel.purchase_abonement_usual_amount,
                    purchase_abonement_usual_count : jsonAnswerStatus.analyticsDashboardSearchViewModel.purchase_abonement_usual_count,
                    purchase_abonement_raz_amount : jsonAnswerStatus.analyticsDashboardSearchViewModel.purchase_abonement_raz_amount,
                    purchase_abonement_raz_count : jsonAnswerStatus.analyticsDashboardSearchViewModel.purchase_abonement_raz_count,
                    profit : jsonAnswerStatus.analyticsDashboardSearchViewModel.profit,
                    danceGroupMicroAnalyticsViewModels : jsonAnswerStatus.analyticsDashboardSearchViewModel.danceGroupMicroAnalyticsViewModels,
                });
            } else {
                this.setState({
                    isError : true
                });
            }
        } catch(ex){
            console.log(ex);
            this.setState({
                isError : true
            });
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    render(){

        let dashboardPanelContent = <></>
        if(this.state.isLoading){
            dashboardPanelContent = <SystemLoadingPage />
        } else if(this.state.isError){
            dashboardPanelContent = <SystemErrorPage tryAgain={this.search} />
        } else {
            dashboardPanelContent = <DashboardPanel
                purchase_abonement_cash_amount={this.state.purchase_abonement_cash_amount}
                purchase_abonement_cashless_amount={this.state.purchase_abonement_cashless_amount}
                purchase_abonement_count={this.state.purchase_abonement_count}
                teacher_salary_amount={this.state.teacher_salary_amount}
                teacher_salary_count={this.state.teacher_salary_count}
                users_new_count={this.state.users_new_count}
                visits_count={this.state.visits_count}
                purchase_abonement_usual_amount={this.state.purchase_abonement_usual_amount}
                purchase_abonement_usual_count={this.state.purchase_abonement_usual_count}
                purchase_abonement_raz_amount={this.state.purchase_abonement_raz_amount}
                purchase_abonement_raz_count={this.state.purchase_abonement_raz_count}
                profit={this.state.profit}
                danceGroupMicroAnalyticsViewModels={this.state.danceGroupMicroAnalyticsViewModels}
            />
        }

        return (
            <div className="row page dashboard">
                <div className="col-12 row filter-block div-form">
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                        <Form>
                            <Form.Group controlId="formFilterDateFrom">
                                <Form.Label>С даты:</Form.Label>
                                <Form.Control type="date" name="date_from" defaultValue={this.state.date_from} 
                                onChange={this.filterListener}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                        <Form>
                            <Form.Group controlId="formFilterDateTo">
                                <Form.Label>По дату:</Form.Label>
                                <Form.Control type="date" name="date_to" defaultValue={this.state.date_to} 
                                onChange={this.filterListener}
                                />
                            </Form.Group>
                        </Form>
                    </div>

                    <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                        <Button variant="success" type="button" 
                        onClick={this.search} disabled={this.state.isLoading}
                        >
                            Обновить
                        </Button>
                    </div>

                    
                </div>

                {dashboardPanelContent}



            </div>
        )
    }
}


export default function DashboardPage(props){
    const navigate = useNavigate();
    return(<Dashboard navigate={navigate}></Dashboard>)
}


/*
<div className="col-12">
    <p className="text-center ps">- для подробной информации щелкните по нужному блоку -</p>
</div>
*/