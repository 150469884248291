


function getBaseDomain(){
    if((window.location.hostname).indexOf("localhost") + 1){
        //return "https://api.base.13dancestudio.ru";
        return "http://dancestudio-erp.local";
    } else {
        return "https://api.base.13dancestudio.ru";
        //return "https://api.test.13dancestudio.ru";
    }
}


const constant = {
    "baseDomain" : getBaseDomain(),
}

export default constant;